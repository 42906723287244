import { Link } from 'react-router-dom'
import { Masonry } from '@mui/lab'

import { Container, PageWrapper, Label } from '../../../components/design-system'
import { Card } from '../components/card'

import styles from './styles.module.scss'
import classNames from 'classnames'

export const CreatorCard = ({
    children,
    className,
    to = "",
    message = 'Create',
    ...props
}: {
    children: React.ReactNode,
    className?: string,
    to?: string,
    message?: string,
    [key: string]: any,
}) => {
    return (
        <Link style={{ textDecoration: 'none' }} to={to} {...props}>
            <Card className={classNames(styles.card, className)}>
                <Label className={styles.label}>{message === 'Create' ? 'Create' : message}</Label>
                {children}

                <svg className={styles.card__icon} xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                    <path d="M24 40 21.9 37.85 34.25 25.5H8V22.5H34.25L21.9 10.15L24 8L40 24Z"/>
                </svg>
            </Card>
        </Link>
    )
}

export const CreatorDashboard = () => {
    return (
        <PageWrapper>
            <Container>
                <Masonry sx={{ width: 'auto' }} columns={{ xs: 1, lg: 2 }}>
                    <CreatorCard to="/creator/mint">
                        <h1>Token</h1>
                    </CreatorCard>

                    <CreatorCard to="/creator/auction">
                        <h1>Token Auction</h1>
                    </CreatorCard>
                    <CreatorCard to="/creator/swap">
                        <h1>Token Swap</h1>
                    </CreatorCard>
                    <CreatorCard to="/creator/project-shells" message="Manage">
                        <h1>Artblock Projects</h1>
                    </CreatorCard>
                </Masonry>
                
                <CreatorCard to="/" message="Go to">
                        <h1>Home</h1>
                </CreatorCard>
            </Container>
        </PageWrapper>
    )
}