import { useState, useEffect } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { DropdownInput, Container, Loader, Label } from "../../components/design-system"
import { Token } from "../../components/token/token"
import { getTokenInfo } from "../../components/token/dummytoken"
import { EventRouter } from "../../routers/event/event-router"
import { getTail } from "../../routers/global-router"
import { Masonry } from "@mui/lab"

import type { IToken } from "../admin/manage-tokens/tokens"
import { IEvent } from "../admin/manage-events"

import styles from './styles.module.scss'

import { getFxhashSwapData, getMetadata, getSwaps, getVCAFixedSaleDrop } from "../../utils/crypto"
import { fetchGraphcms } from "../../libs/graphcms"
import { DummyToken } from "../../components/token/dummytoken"
import { getToken } from "../admin/manage-tokens/tokens"
import { getHistory } from "../../utils/crypto"
import { ArtblockProject } from "../../components/token/artblockproject"
import { ExhibitionEvent } from "./exhibitionevent"
import { SubExhibition } from "./subexhibition"

import {
    DEFAULT_VCA_TZ_ADDRESS
   } from "../../constants"

const getEvent = async ({
    id,
    setEvent,
    setToken,
    setArtblockProjects,
    setSubEvents,
    setLoading,
    query,
    network,
    navigate,
}: {
    id: string;
    setEvent: any;
    setToken: any;
    setArtblockProjects: any;
    setSubEvents: any;
    setLoading: any;
    query: string;
    network: "all" | "ethereum" | "tezos";
    navigate: any;
}) => {

    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: query,
        variables: {
            id,
            network: network === "all" ? null : network
        }
    }).then(async (response) => {
        const event = response.events?.[0]
        let tokens = event?.tokens

        let artblockprojects = event?.artblocksProjects
        let subevents = event?.subevents

        if (!event) {
            return navigate("/404", { replace: true })
        }
/*
        for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i]
            
            let metadata: any = {};
            let swapdata: any = {};

            // actual tokens
            if (!token.externalUrl) {
                try {

                    // VCA Fixed sale tokens
                    if(token.saleType.toLowerCase().includes('vca-fixed') && token.cryptoNetwork == "ethereum") {
                         metadata = await getVCAFixedSaleDrop({
                            contract: token.contract,
                            id: token.tokenId
                        })

                        tokens[i].availability = metadata.balance.toNumber() || 0
                    }

                    else {
                         metadata = await getMetadata({
                            network: token.cryptoNetwork,
                            contract: token.contract,
                            id: token.tokenId,
                        })
                        

                        const res: any = await getToken(token.contract, token.cryptoNetwork, token.tokenId)

                        if (token.saleType !== "auction") {
                           swapdata = await getSwaps({
                            network: token.cryptoNetwork,
                            contract: token.contract,
                            id: token.tokenId,
                            minterAddress: metadata.creators[0]?.creator_address,
                            vcaAddress: res.tokens[0].event.account.tezos

                          })
                        } else {
                            // Retrieves auction history if saleType == 'auction'
                            const history:any = await getHistory({
                                network: token.cryptoNetwork,
                                contract: token.contract,
                                id: token.tokenId,
                            })

                            if (history.status == "IN_PROGRESS" || history.status == "active" || history.status == "APPROVED") {
                                tokens[i].ongoingAuction = true;
                            }
                        }

                        tokens[i].availability = swapdata ? (swapdata[0]?.amount_left || 0) : 0 

                    }

                    tokens[i].metadata = metadata || {}
                    tokens[i].swapdata = swapdata || {}
                } catch (error) {
                    console.log(error)
                }
            }

            // dummy tokens - print / fxhash
            else {
                // fxhash tokens
                if (token.saleType && token.saleType.toLowerCase().includes('fxhash') && token.cryptoNetwork == "tezos") {
                    try {
                        const swapdata: any = await getFxhashSwapData(token.tokenId)

                        // Only set token availability when sale started, so its position is accurate
                        if(swapdata.pricingFixed && swapdata.pricingFixed.opensAt <= new Date().toISOString() ||  
                        swapdata.pricingDutchAuction && swapdata.pricingDutchAuction.opensAt <= new Date().toISOString()) {
                            
                            let reserveAmt = 0;

                            //obtain reserves
                            for (let i = 0; i < swapdata.reserves.length; i++) {
                                reserveAmt+= swapdata.reserves[i].amount;
                            }

                            tokens[i].availability = swapdata.balance - reserveAmt
                        }

                        // set token availability to 0 if sale has not started so it's at the back
                        else {
                            tokens[i].availability = 0
                        }

                        tokens[i].swapdata = swapdata || {}

                    } catch (error) {
                        console.log(error)
                    }
                }

                // physical print tokens
                else {
                 tokens[i].availability = 0;
                }
            }
        }

        // Filter out ongoing auction tokens and sort remaining based on availability
        const nonAuctionedTokens = tokens.filter((token: any) => !token.ongoingAuction)
        nonAuctionedTokens.sort((a: any, b: any) => b.availability - a.availability);

        // Retrieve all ongoing auctioned tokens
        const ongoingAuctions = tokens.filter((token: any) => token.ongoingAuction)

        // Insert list of ongoing auction tokens infront of swap tokens
        const sortedTokens = [...ongoingAuctions, ...nonAuctionedTokens] 
*/
        setEvent(event)
        //setToken(sortedTokens)
        setToken(tokens)
        setArtblockProjects(artblockprojects)
        await subevents.sort((a: any,b: any) => a.sortEvent - b.sortEvent);
        setSubEvents(subevents)
        
        setLoading(false)
    }).catch((error) => {
        console.log(error)
    })
}


export const EventView = ({
    query,
}: {
    query: string;
}) => {
    const navigate = useNavigate()

    const [event, setEvent] = useState({} as IEvent)
    const [tokens, setTokens] = useState([])
    const [artblockProjects, setArtblockProjects] = useState<any>({})
    const [subEvents, setSubEvents] = useState<any>({})

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const id = window.location.pathname.split("/")[2]
        const network = window.location.pathname.split("/")[3] !== "all" ? (
            window.location.pathname.split("/")[3] === "eth" ? "ethereum" : "tezos"
        ) : "all" 

        getEvent({
            id,
            setEvent,
            setToken: setTokens,
            setArtblockProjects,
            setSubEvents,
            setLoading,
            query,
            network: network,
            navigate: navigate,
        })
    }, [])

    if (loading) {
        return (
            <Loader />
        )
    }
    if (event.eventType == 'exhibition') {
        return (
            <Container>
                <Masonry columns={{ xs: 1, sm: 1, lg: 1 }}>
                {subEvents.map((subEvent: any) => {
                    return(
                        <ExhibitionEvent
                            id={subEvent.id}
                            name={subEvent.name}
                            artblocksProjects={subEvent.artblocksProjects}
                            tokens={subEvent.tokens}
                        />
                    )
                })}
                </Masonry>
            </Container>
        )
    }
    if (event.eventType == 'sub-exhibition') {
        return (
            <>
            {subEvents.map((subEvent: any) => {
                return(
                    <SubExhibition
                        id={subEvent.id}
                        name={subEvent.name}
                        artblocksProjects={subEvent.artblocksProjects}
                        tokens={subEvent.tokens}
                    />
                )
            })}
            </>
        )
    }
    else {
        return (
            <Container>
                <div className={styles.network}>
                    <h2 style={{ marginRight: '1rem' }}>Artworks on</h2>

                    <DropdownInput max list={[
                            'all',
                            'eth',
                            'xtz'
                        ]}

                        onChange={(value) => {
                            if (value === getTail({ path: window.location.pathname, depth: 3 })) return
                            console.log(value)
                            
                            navigate(event.id + "/" + value, { replace: true })
                        }}

                        value={getTail({ path: window.location.pathname, depth: 3 })}
                        
                        className={styles.network__dropdown}
                    />
                </div>
                <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                    {tokens.map((token: IToken) => {
                        return token.externalUrl ? 
                            (
                            <DummyToken
                                id={token.tokenId}
                                to={token.externalUrl}
                                metadata={token.metadata}
                                saleStart={token.saleStart}
                                saleEnd={token.saleEnd}
                                saleType={token.saleType}
                                cryptoNetwork={token.cryptoNetwork}
                                contract={token.contract}
                                key={token.tokenId}
                            />
                            )
                            :
                            ( 
                            <>
                            <Token
                                network={token.cryptoNetwork}
                                contract={token.contract}
                                id={token.tokenId}
                                key={token.contract + token.tokenId.toString()}
                                additionalInfo={token.additionalInfo}
                                externalUrl={token.externalUrl}
                                saleStart={token.saleStart}
                                saleEnd={token.saleEnd}
                                saleType={token.saleType}
                                to={"/token/" + token.cryptoNetwork + "/" + token.contract + "/" + token.tokenId}
                            />
                        </>
                        )  
                        })
                    }
                </Masonry>
                <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                    {artblockProjects.map((artblockProject: any) => {
                        return(
                            <ArtblockProject
                                id={artblockProject.id}
                                title={artblockProject.title}
                                projectId={artblockProject.projectId}
                                mintPrice={artblockProject.mintPrice}
                                artistAddress={artblockProject.artistAddress}
                                contract={process.env.REACT_APP_ARTBLOCKS_CONTRACT}
                                to={"/token/ethereum" + "/" + process.env.REACT_APP_ARTBLOCKS_CONTRACT + "/" + artblockProject.projectId}
                            />
                        )
                    })}
                </Masonry>
            </Container>
        )
    }

}

export const Event = () => {
    const navigate = useNavigate()
    
    const id = window.location.pathname.split("/")[2]
    const network = window.location.pathname.split("/")[3]

    return (
        <Container>

            <EventRouter />
        </Container>
    )
}