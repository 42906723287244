import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { connectMetamaskBackground } from '../../libs/crypto/ethereum'
import { connectBeaconBackground } from '../../libs/crypto/tezos'
import { ethereumStore } from '../../state/crypto/ethereumStore'

import { Logo } from "./components/profile/logo"
import { Profile } from "./components/profile/profile"

import styles from './styles.module.scss'

export const Header = () => {
    useEffect(() => {
        connectMetamaskBackground(window)
        connectBeaconBackground()
    }, [])

    return (
        <div className={styles.header}>
            <Link className={styles.logo__container} to="/">
                <Logo />
            </Link>
            <Profile />
        </div>
    )
}