import { useState, useEffect, useRef } from "react"
import { AnimatePresence, motion } from 'framer-motion'

import { changeBeaconAccount } from "../../../../libs/crypto/tezos"
import { changeMetamaskAccount } from "../../../../libs/crypto/ethereum"
import { ethereumStore } from "../../../../state/crypto/ethereumStore"
import { tezosStore } from "../../../../state/crypto/tezosStore"

import { HeaderDropdown } from "./dropdown"
import { ButtonLink, useDetectClickOutside } from "../../../design-system"
import Jazzicon from '@raugfer/jazzicon';


import styles from './styles.module.scss'

export const Profile = () => {

    function buildDataUrl(address: string): string {

        // Jazzicon only works for Eth address, use a default 0x00 address to generate Jazzicon
        // if Eth wallet is not connected 
        if (!/^0x[0-9a-fA-F]{40}$/.test(address)) {
            address = "0x0000000000000000000000000000000000000000"
        }

        return 'data:image/svg+xml;base64,' + btoa(Jazzicon(address));
    }

    const ethereumState = ethereumStore()
    const tezosState = tezosStore()

    const [networks, setNetworks] = useState([])
    const [address, setAddress] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const dropdownRef = useRef<HTMLDivElement>(null)
    useDetectClickOutside(dropdownRef, () => setIsOpen(false))

    useEffect(() => {
        if (!ethereumState.address && !tezosState.address) {
            setAddress('')
        }

        let networks: any = []

        if (ethereumState.address) {
            networks.push({
                name: 'Ethereum',
                address: ethereumState.address,
                changeAccounts: () => changeMetamaskAccount(window)
            })
        }

        if (tezosState.address) {
            networks.push({
                name: 'Tezos',
                address: tezosState.address,
                changeAccounts: changeBeaconAccount
            })
        }

        setNetworks(networks)
        setAddress(ethereumState.address || tezosState.address)
    }, [ethereumState.address, tezosState.address])

    return (
        networks.length === 0 ? (
            <ButtonLink
                className={styles.log__in}
                to="/log-in?req=any&redirect=/"
            >
                Log In
            </ButtonLink>
        ) : (
            <motion.div
                ref={dropdownRef}

                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}

                className={styles.avatar__container}>
                <img className={styles.avatar} src={buildDataUrl(address)} onClick={() => setIsOpen(!isOpen)}/>
                <HeaderDropdown
                    isOpen={isOpen}
                    networks={networks}

                    key={"header-dropdown"}
                />
            </motion.div>
        )
    )
}