import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Label, Loader } from "../../components/design-system"
import { getCategories, ICategory } from "../admin/manage-event-categories"
import { Container, CustomPagination, LazyLoad } from '../../components/design-system'
import { IPFS_GATEWAY } from '../../constants'
import { getEvents, IEvent } from '../admin/manage-events'
import { motion } from 'framer-motion'
import { Event } from '../../components/event/event'

import styles from './styles.module.scss'

export const Home = () => {
    const [events, setEvents] = useState<IEvent[]>([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);

    useEffect(() => {
        getEvents(setEvents).then(() => {
            setLoading(false)
        })
    }, [])

    // Retrieve past & upcoming events 
    const upcomingEvents = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.endDate > new Date().toISOString())
    })

    const pastEvents = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.endDate <= new Date().toISOString())
    })
    
    // Display Digital Canvas Event
    const digitalCanvasEvent = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "the-digital-canvas")
    })
    // Display Femgen Event
    const femgenEvent = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "femgen-test")
    })
    // Display VCA Invites event
    const invitesEvent = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "vca-invites")
    })

    // Display residency event
    const residencyEvents = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "4c-residency-auction")
    })
    
    // Display ADE event
    const adeEvents = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug.includes("ade-drop"))
    })

    // Display operator event
    const operatorEvent = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.slug === "operator")
    })

    // Display intouch events & past events
    const intouchEvents = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.eventCategory?.slug === "berlin-pop-up")
    })

    const otherEvents = ((events: IEvent[]) => {
        return events.filter((event: IEvent) => event.eventCategory?.slug !== "berlin-pop-up" && event.slug !== "operator" && !event.slug.includes("ade-drop") && 
                                                event.slug !== "vca-invites" && event.slug !== "4c-residency-auction" && !event.slug.includes("test"))
    })

    // Callback function when pagination is clicked
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }
    
    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <Container>

            <motion.div
                className={styles.events}

                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1
                }}

                transition={{ duration: 0.5, delay: 1 }}
            >

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>The Digital Canvas</h1>
                    { digitalCanvasEvent(events).map((event: IEvent) => (
                        
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>) 
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>FEMGEN Miami 2022</h1>
                    { femgenEvent(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>) 
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>VCA Invites London</h1>
                    { invitesEvent(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>) 
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>4C Residency Event</h1>
                    { residencyEvents(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                        </Link>) 
                    ))}
                </div>
                
                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>ADE: NXT Museum x VCA</h1>
                    { adeEvents(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                         </Link>) 
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>OPERATOR x VCA</h1>
                    { operatorEvent(events).map((event: IEvent) => (
                        (<Link className={`${styles.link__wrapper}`}
                        to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                        <Event event={event} />
                         </Link>) 
                    ))}
                </div>

                <div className={`${styles.event__wrapper}`}>
                    <h1 style={{ marginBottom: '1em'}}>IN TOUCH EXHIBITION</h1>
                    { intouchEvents(events).map((event: IEvent) => (
                        
                        // check if eventUrl is present and link to actual event website
                        event.eventUrl ? 

                        (<a className={`${styles.link__wrapper}`}
                             href={event.eventUrl} target="_blank" key={event.id}>
                            <Event event={event} />
                        </a>)
                        
                        :
                        
                        (<Link className={`${styles.link__wrapper}`}
                            to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                            <Event event={event} />
                        </Link>) 
                    ))}
                </div>

                <div>
                    <h1 style={{ marginBottom: '1em'}}>Past Events</h1>
                    { otherEvents(events).slice((page-1)*5, ((page-1)*5)+5).map(event => (
                    
                        (<Link className={`${styles.link__wrapper}`}
                            to={"/event/" + event?.id + "/all" || "/404"} key={event.id}>
                            <Event event={event} />
                        </Link>) 
                    ))}
                </div>
                    <CustomPagination 
                        onChange={handlePageChange}
                        page={page}
                        itemsToDisplay={otherEvents(events)}
                        itemAmountToDisplay={5} />
            </motion.div>



            {/* <Masonry */}
        </Container>
    );
}
