import { useEffect, useState } from "react"
import { Container, DropdownInput, FileInput, Label, NumberInput } from "../../../components/design-system"
import { ethereumStore, getProvider, getSigner } from "../../../state/crypto/ethereumStore"
import { tezosStore } from "../../../state/crypto/tezosStore"
import { Input, Button } from "../../../components/design-system"
import { fetchCollections } from "./collections"
import { generateCover, uploadImage } from "./covers"
import { generateMetadata } from "./metadata"
import { IPFS_GATEWAY } from "../../../constants"
import { waitSignerEthereum, waitSignerTezos } from "../../../libs/crypto/crypto"
import { notificationStore } from "../../../state/global/notificationStore"
import classNames from "classnames"
import { motion } from "framer-motion"

import styles from './styles.module.scss'
import { MockToken } from "../../../components/token/token"
import { getAbi } from "../../../utils/crypto"
import { ethers } from "ethers"

export enum RightsEnum {
    NONE = 'No License / All Rights Reserved',
    PUBLIC = 'CC0 (Public Domain)',
    CC_BY = 'CC BY',
    CC_BY_SA = 'CC BY-SA',
  }

export const Mint = () => {
    const notifications = notificationStore()

    const tezosState = tezosStore()
    const ethereumState = ethereumStore()

    const [collections, setCollections] = useState<any[]>([])
    const [selectedCollection, setSelectedCollection] = useState<any>("Select a Collection")

    const [network, setNetwork] = useState<"tezos" | "ethereum">("ethereum")

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [editions, setEditions] = useState(0)
    const [royalties, setRoyalties] = useState(0)
    const [license, setLicense] = useState<RightsEnum>(RightsEnum.NONE)

    const [file, setFile] = useState<any>()
    const [fileCover, setFileCover] = useState<any>()

    useEffect(() => {
        if (!tezosState.address) return

        fetchCollections(tezosState.address).then(collections => {
            setCollections(collections)
        })
    }, [, tezosState.address])

    const handleMint = async () => {
        const notifID = notifications.addNotification({
            message: "Minting Token: " + name,
            status: "pending"            
        })

        try {
            const cid = await uploadImage(file)

            let img;
            if (file.type.split('/')[0] !== 'image') {
                img = await uploadImage(fileCover)
            }
    
            const target = file.type.split('/')[0] !== 'image' ? img : cid
            console.log(target)
    
            const cover = await generateCover(target, 1024, 1024, 60)
            const thumb = await generateCover(target, 350, 350, 60)
    
            if (network === "tezos") {
                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Generating Metadata"
                })
    
                const image: any = await new Promise((resolve, reject) => {
                    const image = new Image()
                    image.onload = () => resolve(image)
                    image.onerror = reject
                    image.src = IPFS_GATEWAY + cid
                })
              
                const height = image.height
                const width = image.width
        
                const metadata = await generateMetadata.tezos({
                    name,
                    description,
                    editions,
                    royalties,
                    license,
                    artifactUri: "ipfs://" + cid,
                    coverUri: "ipfs://" + (file.type.split('/')[0] !== 'image' ? img : cover),
                    thumbnailUri: "ipfs://" + thumb,
                    creator: tezosState.address,
                    mediaType: file.type,
                    width,
                    height
                })
        
                if (!tezosState?.signer) {
                    await waitSignerTezos()
                }
        
                const Tezos = tezosState.provider
        
                const mintContract = await Tezos.wallet.at("KT1Aq4wWmVanpQhq4TTfjZXB5AjFpx15iQMM")
    
                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Sending Mint Request"
                })
        
                const tx = await mintContract.methods.mint_artist(
                    collections.find(collection => collection.contract === selectedCollection).collection_id,
                    editions,
                    ("ipfs://" + metadata)
                        .split('')
                        .reduce(
                            (hex: any, c: any) =>
                                (hex += c.charCodeAt(0).toString(16).padStart(2, '0')),
                            ''
                        ),
                    tezosState.address
                )
        
                const op = await tx.send({ amount: 0, storageLimit: 390 })
    
                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Waiting on Confirmations"
                })
    
                await op.confirmation(2)
    
                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Minting Complete"
                })
            } else if (network === "ethereum") {
                const metadata = await generateMetadata.ethereum({
                    name,
                    description,
                    royalties,
                    image: "ipfs://" + img,
                    previewURI: "ipfs://" + cover,
                    contentURI: "ipfs://" + cid,
                    animationURI: (file.type.split('/')[0] === "video" || file.type.split('/')[0] === "model") ? "ipfs://" + cid : null,
                    creator: ethereumState.address,
                    mediaType: file.type
                })
            
                const signer = getSigner()
                const provider = getProvider()
    
                if (!signer) {
                    await waitSignerEthereum(window)
                }

                const abi = await getAbi("0xF4398a4d8bf5e267abdA3D62C47F8eF5f43e8c34")
                const tokenContract = new ethers.Contract("0xF4398a4d8bf5e267abdA3D62C47F8eF5f43e8c34", abi, signer)

                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Sending Mint Request"
                })

                const tx = await tokenContract.mint(
                    metadata,
                    ethereumState.address,
                    royalties
                )

                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Minting Complete"
                })

                notifications.resolve(notifID)
            }    
        } catch (error) {
            console.error(error)
            notifications.setNotificationMessage({
                id: notifID,
                message: "Minting Failed",
            })

            notifications.reject(notifID)
        } 
    }

    if (!tezosState.address && !ethereumState.address) {
        return (
            <Container>
                <div className={styles.message}>
                    Please connect a wallet to continue.
                </div>
            </Container>
        )
    }

    const variants = {
        hidden: {
            opacity: 0,
            height: 0,
            width: 0,
            transition: {
                opacity: {
                    duration: 0.5
                },
                height: {
                    delay: 0.5,
                    duration: 0.5
                },
                width: {
                    delay: 0.5,
                    duration: 0.5
                }
            }
        },
        show: {
            opacity: 1,
            height: "auto",
            width: "auto",
            transition: {
                opacity: {
                    delay: 0.5,
                    duration: 0.5
                },
                height: {
                    duration: 0.5
                },
                width: {
                    duration: 0.5
                }
            }
        }
    }

    return (
        <Container>
            <div className={styles.container}>
                <div className={styles.fields__container}>
                    <h1>Mint a Token</h1>

                    <br />

                    <div className={styles.field}>
                        <Label>Network</Label>
                        <div className={styles.multiselect__container}>
                            <Button className={`${styles.multiselect__button} ${network === "ethereum" ? styles.active : null}`} onClick={() => setNetwork("ethereum")}>
                                Ethereum
                            </Button>
                            <Button className={`${styles.multiselect__button} ${network === "tezos" ? styles.active : null}`} onClick={() => setNetwork("tezos")}>
                                Tezos
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className={styles.field}>
                        <Label>Token Title</Label>
                        <Input
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            value={name}
                        />
                    </div>

                    <div className={styles.field}>
                        <Label>Token Description</Label>
                        <textarea
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                            value={description}

                            className={styles.textarea}
                        />
                    </div>

                    <br />

                    <div className={styles.split}>
                        <div className={styles.split__el}>
                            <div className={styles.field}>
                                <Label>Token Royalties</Label>
                                <NumberInput
                                    onChange={(e) => setRoyalties(parseFloat(e.target.value))}
                                    placeholder="0%"
                                    value={royalties}

                                    min={0}
                                    max={25}
                                    step={0.1}
                                />
                            </div>
                        </div>

                        <div className={styles.split__el}>
                            <motion.div
                                variants={variants}
                                animate={network === "tezos" ? "show" : "hidden"}
                            
                                className={styles.field}
                            >
                                <Label>Token Editions</Label>

                                <div style={{ position: 'relative' }}>
                                    <NumberInput
                                        onChange={(e) => setEditions(parseInt(e.target.value))}
                                        placeholder="1"
                                        value={editions}

                                        min={1}
                                        max={10000}
                                        step={1}

                                        disabled={network === "ethereum"}
                                    />
                                </div>
                            </motion.div>
                        </div>
                    </div>

                    <br />

                    <motion.div
                        variants={variants}
                        animate={network === "tezos" ? "show" : "hidden"}

                        className={styles.field}
                    >
                        <Label>License</Label>

                        <div style={{ position: 'relative' }}>
                            <DropdownInput
                                list={
                                    ["No License / All Rights Reserved", "CC0 (Public Domain)", "CC BY", "CC BY-SA"]
                                }
                                onChange={(e) => setLicense(e as RightsEnum)}
                                value={license}
                                // disabled={network === "ethereum"}
                            />
                        </div>
                    </motion.div>

                    <motion.div
                        variants={variants}
                        animate={network === "tezos" ? "show" : "hidden"}                    

                        className={styles.field}
                        style={{ zIndex: 0 }}
                    >
                        <Label>Collection (objkt.com)</Label>

                        <div style={{ position: 'relative' }}>
                            <DropdownInput
                                list={
                                    collections.length > 0
                                    ? collections.map(collection => collection.contract)
                                    : ["Select a Collection"]
                                }

                                onChange={(e) => setSelectedCollection(e as string)}
                                value={selectedCollection}
                                disabled={network === "ethereum"}
                            />
                        </div>
                    </motion.div>
                    
                    {/** TODO: ADD EVENT DROPDOWN */}

                    <br />

                    <div className={styles.field}>
                        <Label>Add File</Label>

                        <FileInput
                            accept={"*"}
                            onChange={(e) => {
                                setFile(e?.target?.files?.[0])
                            }}

                            message={file ? file.name : "Upload File"}
                        />
                    </div>

                    {file && file?.type.split('/')[0] !== 'image' && (
                        <div className={styles.field}>
                            <Label>Add Cover</Label>

                            <FileInput
                                accept={"image/*"}
                                onChange={(e) => {
                                    setFileCover(e?.target?.files?.[0])
                                }}

                                message={fileCover ? fileCover.name : "Upload Cover"}
                            />
                    </div>
                    )}

                    <Button className={styles.mint__button} onClick={() => {
                        handleMint()
                    }}>
                        Mint
                    </Button>
                </div>
                <div className={styles.mock__container}>
                    <div className={styles.mock__inner__container}>
                        <MockToken
                            artifact={file}
                            display={fileCover || file}
                            mediaType={file?.type}

                            title={name}
                            creator={network === "ethereum" ? ethereumState.address : tezosState.address}
                            network={network}
                        />
                    </div>
                </div>

            </div>
        </Container>
    )
}