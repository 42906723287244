import { getProvider, getSigner } from "../../state/crypto/ethereumStore"
import { waitSignerEthereum } from "../../libs/crypto/crypto"
import { getAbi, getAbProjectInfo, getAbTokenFeatures } from "../../utils/crypto"
import { ethers } from "ethers"
import { useEffect, useState } from "react"
import { Loader, Container, Label, Button, Modal, LazyLoad, Countdown } from "../../components/design-system"
import styles from "./styles.module.scss"
import { ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS, ARTBLOCKS_GENERATOR_ADDRESS, ARTBLOCKS_IMAGE_PREVIEW, ARTBLOCKS_FLEX_CONTRACT_ADDRESS, ARTBLOCKS_GENERATOR_ADDRESS_WITHOUT_SLASH } from "../../constants"

import { ArtBlockLinks, TokenSaleInfoBox, ArtBlockLicenseAndLibrary, ArtBlockDisplayNotes, ArtBlockDisplayAdditionalDetails, IndividualTokenInfo } from "./saleinfo"
import { notificationStore } from "../../state/global/notificationStore"
import { ArtBlocksMintedPreview } from "../../components/token/artblocks-otherstoken"
import { MintConfirmation } from "../../components/mint-confirmation"
import { CustomPagination } from "../../components/design-system"
import { useLocation } from "react-router"
import { getDateTime } from "../../utils/time"
import ReactMarkdown from 'react-markdown'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Tooltip from '@mui/material/Tooltip';


export const ArtBlocksViewer = ({projectId, setBroken} : {projectId: number, setBroken: any}) => {
    const fullscreenhandle = useFullScreenHandle();
    const notifications = notificationStore()
    const location = useLocation();


    const [project, setProject] = useState<any>({})
    const [randomTokenId, setRandomTokenId] = useState<string>("000000")
    const [mintedArr, setMintedArr] = useState<any>([])
    const [closeModal, setCloseModal] = useState<boolean>(true)
    const [mintedTokenId, setMintedTokenId] = useState<number>(0)
    const [isRendering, setIsRendering] = useState<boolean>(true)
    const [page, setPage] = useState(1);
    const [openPossibilitiesModal, setOpenPossibilitiesModal] = useState(false)
    const [possibilityToken, setPossibilityToken] = useState<string>("0xd1a91844044042b6a634a315b0c5e8c300e83be8ffc87e1df14f9be54583582ab")
    const [randomTokenNumber, setRandomTokenNumber] = useState<string>("0")
    const [tokenFeatures, setTokenFeatures] = useState<any>({})
    
    const [availability, setAvailability] = useState<number>(0)
    
    useEffect(() => {
      const fetchProjectDetails = async () => {
        setInterval(async () => {
          try {
            const projectDetails: any = await getAbProjectInfo(projectId)
            const priceDetail: number = parseFloat(ethers.utils.formatEther(projectDetails.tokenPriceInWei))

            setAvailability(projectDetails.maxInvocations-projectDetails.invocations.toNumber())
          } catch (error) {
            setBroken(true)
            console.error(error)
          }
        }, 15000);  
      }
      fetchProjectDetails();
    }, []);
    // Callback function when pagination is clicked
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value)
    }

    // Get new Token for possibility modal - new version
    const generateNewPossibility = () => {
      let getnerativeHash: string = "";
      for (var i = 0; i < 64; i++) {
        getnerativeHash += Math.floor(Math.random() * 16).toString(16);
      }
      const generativeRandomNumber: any = Math.floor(Math.random() * project.maxInvocations + 1);
      const generativeFormattedNumber = generativeRandomNumber.toString().padStart(6, '0');
      //const newTokenURI = "https://generator-staging-goerli.artblocks.io/0xabf9b1c2c14399cfd1753be2090b7da3c0a1434b-17/17000153/0xd1a91844044042b6a634a315b0c5e8c300e83be8ffc87e1df14f9be54583582ab"
      setRandomTokenNumber(generativeFormattedNumber)
      setPossibilityToken(getnerativeHash)
    }
    
    const hasMarkdownChar = (strToCheck: any) => {
      const markdownChars = "#*_";
      for (let i = 0; i < strToCheck.length; i++) {
        if (markdownChars.indexOf(strToCheck[i]) !== -1) {
          return true;
        }
      }
      return false;
    }

    const handlePurchase = async () => {
      const notifId = notifications.addNotification({
        message: 'Minting...',
        status: 'pending',
      })
    
      try {
          const signer = getSigner()
    
          if (!signer) {
              await waitSignerEthereum(window)
          }

          let overrides = {
            value: ethers.utils.parseEther((project.price).toString())
          }
    
          const minterAbi = await getAbi(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS)
          const coreAbi =  await getAbi(ARTBLOCKS_FLEX_CONTRACT_ADDRESS)
          const abMinter = new ethers.Contract(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS, minterAbi, signer)
          const abCore = new ethers.Contract(ARTBLOCKS_FLEX_CONTRACT_ADDRESS, coreAbi, signer )

          const provider = getProvider()

          try {
            // Mint using `purchase` in Minter Contract
            const tx = await abMinter.purchase(projectId, overrides)

            await provider.waitForTransaction(tx.hash);

            // Get the transaction receipt
            const receipt = await provider.getTransactionReceipt(tx.hash);

            // Check the status of the transaction
            if (receipt.status === 1) {
                notifications.setNotificationMessage({
                  id: notifId,
                  message: "Token succesfully minted"
                })
        
                notifications.resolve(notifId)

                const tokenInfo = await abCore.projectTokenInfo(projectId)
                const tokenId = tokenInfo.invocations.toNumber()

                // Open pop up modal showing minted token
                setCloseModal(false)
                setMintedTokenId(tokenId);
      
                // Set loading state until image is completely rendered
                setTimeout(() => {
                  setIsRendering(false)
                }, 60000); 

            } else {
              // Catch any errors
              notifications.setNotificationMessage({
                id: notifId,
                message: "Error minting token"
              })

              notifications.reject(notifId)
            }
            
          } catch(error) {
            // Catch any errors
            console.error(error);

            notifications.setNotificationMessage({
              id: notifId,
              message: "Error minting token"
            })

            notifications.reject(notifId)
          }

        } catch(error) {
          
          console.log(error)

          notifications.setNotificationMessage({
              id: notifId,
              message: "Error minting token"
          })

          notifications.reject(notifId)

        }
    }

    const getTokenFeatures = async (tokenId: string) => {
      const projectInfo:any = await getAbTokenFeatures(projectId, "000000")
      setTokenFeatures(projectInfo.features)
    }
    
    useEffect(() => {
      const fetchProjectDetails = async () => {

          try {
            const projectDetails: any = await getAbProjectInfo(projectId)

            const priceDetail: number = parseFloat(ethers.utils.formatEther(projectDetails.tokenPriceInWei))
            // Reconstruct object to correct types
            setProject({
                artist: projectDetails.artist,
                artistAddress: projectDetails.royaltyInfo.artistAddress,
                currency: 'ETH',
                description: projectDetails.description,
                invocations: projectDetails.invocations.toNumber(),
                license: projectDetails.license || null,
                maxInvocations: projectDetails.maxInvocations,
                projectName: projectDetails.collection_name.split(`by ${projectDetails.artist}`)[0],
                royalties: projectDetails.royaltyInfo.royaltyFeeByID || 0,
                library: JSON.parse(projectDetails.scriptJSON).type || null,
                aspectRatio: JSON.parse(projectDetails.scriptJSON).aspectRatio || 1,
                saletype: ethers.utils.formatEther(projectDetails.basePrice) == ethers.utils.formatEther(projectDetails.startPrice) ? 'fixed' : 'auction',
                auctionDetails: {
                  startPrice: parseFloat(ethers.utils.formatEther(projectDetails.startPrice)),
                  basePrice: parseFloat(ethers.utils.formatEther(projectDetails.basePrice)),
                  timeInterval: projectDetails.priceDecayHalfLifeSeconds.toNumber()/60
                },
                price: priceDetail.toFixed(4),
                startTime: getDateTime(projectDetails.timestampStart.toNumber()*1000)
            })
            setAvailability(projectDetails.maxInvocations-projectDetails.invocations.toNumber())
          } catch (error) {
            setBroken(true)
            console.error(error)
          }
        }

        fetchProjectDetails();
    }, [])

    useEffect(() => {
      setIsRendering(true)
    }, [closeModal])

    useEffect(() => {
      if (project.invocations > 0) {
         // if accessing individual token page
         if (window.location.pathname.split("/")[5]) {
           //window.scrollTo(0, 0);
           setRandomTokenId(window.location.pathname.split("/")[5])

           // get owner of token

           // get Features
           getTokenFeatures(window.location.pathname.split("/")[5].padStart(6, "0"))
         } else {
          // Grab random token to display
          const randomNum = Math.floor(Math.random() * (project.invocations));
          const paddedNum =  randomNum.toString().padStart(6, "0");
          setRandomTokenId(paddedNum)
         }

         // Create array with all previous minted token Ids
         const tokenIdArray = Array.from(Array(project.invocations).keys())
         setMintedArr(tokenIdArray)
      }
    }, [project, location.pathname])

    
    // individual token page
    if (window.location.pathname.split("/")[5] && 
        window.location.pathname.split("/")[5] < project.invocations) {

      return (
      project && Object.keys(project).length == 0 ? (
            <Loader />
        ) : (
          <Container>
            <h1 className={styles.info__title}>
              {project.projectName} #{randomTokenId}
            </h1>
            <Label className={styles.creator}>
                {project.artist}
            </Label>

            <div className={`${styles.media__container} ${styles.iframe}`}>
            <FullScreen handle={fullscreenhandle} >
              <img
              src={ARTBLOCKS_IMAGE_PREVIEW+projectId.toString()+randomTokenId.toString().padStart(6, "0")+'.png'}
              
              className={styles.img__cover}
              style={{
                height: `calc(0.5 * 70vw * ${1/project.aspectRatio})`,
              }}
              alt={`${project.projectName} - Token ID: #${randomTokenId}`}
            /> 
            </FullScreen>
          </div>

          <div className={styles.token__container}>
            {/* <button onClick={toggleFullScreen}>Toggle Full Screen</button> */}
            <div className={styles.links}>
              <div className={styles.link}>
                <Tooltip title="Fullscreen">
                  <FullscreenIcon 
                    onClick={fullscreenhandle.enter}
                    className={styles.icon__style}
                  />
                </Tooltip>
              </div>
              <ArtBlockLinks 
                displayedId={randomTokenId.toString().padStart(6, "0")} 
                projectId={projectId}
              />
            </div>
          </div>


          <div className={styles.token__container}>
              <div className={styles.info__container}>
                  <h2 className={styles.info__about}>
                    About {project.projectName} #{randomTokenId}
                  </h2>
                  <div className={styles.info__features}>
                    { tokenFeatures && Object.entries(tokenFeatures).length > 0 ? 
                    (
                      Object.entries(tokenFeatures).map((feature: any[]) => (
                        <><p className={styles.features__title}>{feature[0]}</p> 
                          <p className={styles.features__value}>{feature[1]}</p>
                        </>
                      ))
                    ) : "No features available"
                    }
                  </div>
              </div>

            <div className={styles.info__container}>
              <div className={styles.auction__container}>
                <IndividualTokenInfo
                  projectId={projectId}
                  tokenId={randomTokenId}
                  supply={project.maxInvocations}
                />                  
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: "5rem" }}></div>
          </Container>
          
      ))
    }

    else {
      return (
        project && Object.keys(project).length == 0 ? (
            <Loader />
        ) : (
          <Container>
          <h1 className={styles.info__title}>
            {project.projectName}
          </h1>
          <Label className={styles.creator}>
              {project.artist}
          </Label>
          <div className={`${styles.media__container} ${styles.iframe}`}>
             {/* <iframe
                  src={ARTBLOCKS_GENERATOR_ADDRESS+projectId+randomTokenId}
                  style={{
                    height: `calc(0.5 * 90vw * ${1/project.aspectRatio})`,
                    width: 'calc(1 * 150vw)'
                  }}
                  allowFullScreen={true}
                /> */}
            { project.invocations > 0  && (
            <FullScreen handle={fullscreenhandle} >
              <img
              src={ARTBLOCKS_IMAGE_PREVIEW+projectId.toString()+randomTokenId.toString().padStart(6, "0")+'.png'}
              
              className={styles.img__cover}
              style={{
                height: `calc(0.5 * 70vw * ${1/project.aspectRatio})`,
              }}
              alt="Generative art preview"
            /> 
            </FullScreen>
            ) 
            }
          </div>
          <div className={styles.token__container}>
            {/* <button onClick={toggleFullScreen}>Toggle Full Screen</button> */}
            <div className={styles.links}>
              <div className={styles.link}>
                <Tooltip title="Fullscreen">
                  <FullscreenIcon 
                    onClick={fullscreenhandle.enter}
                    className={styles.icon__style}
                  />
                </Tooltip>
              </div>
              <ArtBlockLinks 
                displayedId={randomTokenId} 
                projectId={projectId}
              />
            </div>
          </div>

          <div className={styles.token__container}>
              <div className={styles.info__container}>
                  <h2 className={styles.info__about}>
                    About {project.projectName}
                  </h2>
                  <div className={styles.info__about}>
                    <Button
                      onClick={() => setOpenPossibilitiesModal(true)}
                    >
                    <p className={styles.info__possibilities}>
                      Explore Possibilities
                    </p>
                    </Button>
                  </div>

                  <div className={styles.info__description}>
                    {hasMarkdownChar(project.description) ? (
                      <ReactMarkdown>{project.description}</ReactMarkdown>
                    ) : (
                        <>{project.description}</>
                    )}
                  </div>
              </div>
              <div className={styles.info__container}>

              { project.invocations != project.maxInvocations ? 
                (
                    <>
                    <div className={styles.auction__container}>
                      <TokenSaleInfoBox
                        network="ethereum"
                        supply={project.maxInvocations}
                        balance={availability}
                        price={project.price*10e17}
                        royalties={project.royalties}
                        license={project.license}
                        library={project.library}
                        releaseinfos={project.startTime}
                        type={project.saletype}
                        auctionDetails={project.auctionDetails}
                      />
                      {project.startTime > new Date().toISOString() ? (
                        <>
                        <div className={styles.editions__info__box}>
                          <Countdown target={new Date(project.startTime)} className={styles.countdown}/>
                        </div> 
                        </>
                      ):(
                        <>
                        <div className={styles.bid__container}>                           
                          <Button 
                            style={{ width: "100%" }}
                            className={styles.swap__submit}
                            onClick={() => handlePurchase()}
                          >
                          Mint for {project.price}&nbsp;ETH
                          
                          </Button>
                        </div>
                        </>
                      )}
                    </div>
                    {/*
                    <div className={styles.social__share}>
                      <div className={styles.social__share__box}>
                        <TwitterShareButton 
                          url={"https://auction.verticalcrypto.art/token/ethereum/" + window.location.href.split('/')[5] + "/" + window.location.href.split('/')[6]}
                          title={"Share your artwork\n"}
                        >
                        <div className={styles.social__share__text}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" className="bi bi-twitter" viewBox="0 0 16 12">
                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                          </svg>
                        </div> &nbsp;
                        <div className={styles.social__share__text}>
                          Share on Twitter
                        </div>
                        </TwitterShareButton>
                      </div>
                    </div>
                    <br />
                    */}
                    {/*
                  <div className={styles.charity__info}>
                    <div className={styles.charity__headline}>
                      <FavoriteBorderIcon 
                          className={styles.charity__icon__style}
                      />
                      <div className={styles.charity__headline__text}>
                        Charitable giving
                      </div>
                    </div>
                    <div className={styles.charity__text}>
                      100% of the artist's proceeds (on both primary and secondary sales) will be donated to three organizations supporting female and non-binary technologies, including AnitaB.org, Code/Art, and Girls Who Code.
                    </div>
                  </div>
                  <ArtBlockDisplayNotes 
                  />
                  <ArtBlockDisplayAdditionalDetails
                    license={project.license}
                    library={project.library} 
                  />
                    */}
                  </>
              ):(
                <>
                  <br />
                  <br />
                  <ArtBlockLinks 
                    displayedId={randomTokenId} 
                    projectId={projectId} 
                  />
                    
                  <ArtBlockLicenseAndLibrary
                    license={project.license}
                    library={project.library} 
                  />
                  <br/>
                  <p className={styles.sold_out}>Sold out</p>
                </>
              )
              }
              </div>
            </div>
            <div className={styles.minted__container}>
              <h1 className={styles.info__title}>View collection</h1>
                <div className={styles.minted_preview__container}>
                { mintedArr.slice((page-1)*12, ((page-1)*12)+12).map((token: number) => (
                    <ArtBlocksMintedPreview projectId={projectId} tokenId={token} />
                ))}
                </div>
              
                <CustomPagination 
                    onChange={handlePageChange}
                    page={page}
                    itemsToDisplay={mintedArr} 
                    itemAmountToDisplay={12} />

            </div>
            <MintConfirmation key={mintedTokenId}
                              tokenId={mintedTokenId} 
                              projectId={projectId}
                              aspectRatio={project.aspectRatio}
                              open={!closeModal} 
                              setClose={setCloseModal} 
                              loading={isRendering}/>
            <Modal
                open={openPossibilitiesModal}
                onClose={() => {
                  setOpenPossibilitiesModal(false)
                }}
                title={project.projectName}
              >
              <Modal.Wrapper>
                  <Label>{project.projectName}</Label>
              </Modal.Wrapper>
              <Modal.Spacer />
              <Modal.Wrapper>
                <div style={{ margin: "2rem auto"}}>
                <iframe
                  src={ARTBLOCKS_GENERATOR_ADDRESS_WITHOUT_SLASH + "-" + projectId + "/" + projectId + randomTokenNumber + randomTokenNumber + "/" + possibilityToken}
                  style={{
                    height: `calc(0.5 * 50vh * ${1/project.aspectRatio})`,
                    width: 'calc(0.5 * 50vh)',
                  }}
                  allowFullScreen={true}
                />
                </div>
                  <Button
                    onClick={generateNewPossibility}
                  >
                    Load iteration
                  </Button>
              </Modal.Wrapper>
            </Modal>
          </Container>
        )
        
     )
    }
}