import { ethers, providers } from "ethers"
import { ethereumStore, getProvider } from "../state/crypto/ethereumStore"
import { Strategies, Networks } from "@zoralabs/nft-hooks"
import { fetchGraphcms, gql } from "../libs/graphcms"

import { ZDK, ZDKChain, ZDKNetwork } from '@zoralabs/zdk'
import { Chain, Network } from "@zoralabs/zdk/dist/queries/queries-sdk"
import { ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS, ARTBLOCKS_GRAPHQL, ETHERSCAN_API, IPFS_GATEWAY, ARTBLOCKS_TOKEN_ADDRESS } from "../constants"

import { getMimeType } from "../components/token/token"
import { VCA_ETH_MINT_ADDRESS, ARTBLOCKS_FLEX_CONTRACT_ADDRESS } from "../constants"
const VCAFixedPrice = require('../constants/vcafixedprice-abi.json')
const VCACustomFixedPrice = require('../constants/vcacustomfp-abi.json')
const EthMint = require('../constants/ethmint-abi.json')
  
  


const fetchAgent = new Strategies.ZDKFetchStrategy(Networks.MAINNET)
const axios = require('axios').default


export const shorten = (text: string, length: number) => {
    if (text?.length > length) {
        return `${text.substring(0, length)}...${text.substring(text.length - length, text.length)}`
    } else {
        return ""
    }
}

export const getAbi = async (address: string) => {
    const { data } = await axios.get(`${ETHERSCAN_API}module=contract&action=getabi&address=${await checksum(address)}&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`)

    return data.result
}

export const checksum = (address: string) => {
    return new Promise<string>((resolve, reject) => {
        try {
            const addr = ethers.utils.getAddress(address)
            resolve(addr)
        } catch (error) {
            reject(error)
        }
    })
}

export const getMetadata = ({
    network,
    contract,
    id
}: {
    network: "ethereum" | "tezos",
    contract: string,
    id: number
}) => {
    const ethereumState = ethereumStore.getState()

    return new Promise(async (resolve, reject) => {
        try {
            if (network === "tezos") {
                
                const data = await axios.post(`https://data.objkt.com/v3/graphql`, {
                    query: query__token__data,
                    variables: {
                        fa2_contract: contract,
                        tokenId: id.toString()
                    },
                    operationName: 'queryTokenData'
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                const metadata = data.data.data.token[0]
                resolve(metadata)
            } else if (network === "ethereum") {
                const address = await checksum(contract) || ""

                const data = await fetchAgent.fetchNFT(
                    address,
                    id.toString()
                )

                resolve(data)
            }
        } catch (error) {
            console.error(error)
            reject(error)
        }
    })
}

export const getVCAFixedSaleDrop = async ({
    contract,
    id,
    custom = false,
}:{
    contract: string,
    id: number,
    custom?: boolean
}) => {
    const ethProvider =  new ethers.providers.InfuraProvider("homestead", process.env.REACT_APP_INFURA_API_KEY);
    const contractInstance = custom ? new ethers.Contract(contract, VCACustomFixedPrice.abi, ethProvider) : 
                                      new ethers.Contract(contract, VCAFixedPrice.abi, ethProvider);
    const dropDetails = await contractInstance.dropDetails(id)
    const artistAddress = custom ? await contractInstance.artist() : null
    const curatorAddress = custom ? await contractInstance.curator() : null
    const curationFee = custom ? await contractInstance.curationFee() : null
    return custom ? {...dropDetails, "artist": artistAddress, "curator": curatorAddress, "curationFee": curationFee} : 
                    dropDetails;
}

export const getRoyalties = async (contract:string, custom:boolean = false) => {
    const ethProvider =  new ethers.providers.InfuraProvider("homestead", process.env.REACT_APP_INFURA_API_KEY);
    const contractInstance = custom ? new ethers.Contract(contract, VCACustomFixedPrice.abi, ethProvider) : 
                                      new ethers.Contract(contract, VCAFixedPrice.abi, ethProvider);
    const contracturi = await contractInstance.contractURI()
    const data = await fetch(contracturi)
    const royalties = await data.json()
    return royalties.seller_fee_basis_points;
}

// Get metadata of drops deployed using VCAFixedPrice Contract
export const getVCAMetadata = async({
    contract,
    id,
    custom = false
}:{
    contract: string;
    id: number;
    custom?: boolean
}) => {
    
    return new Promise(async (resolve, reject) => {
      try {
        // get drop details based on Drop ID
        const metadata = await getVCAFixedSaleDrop({
            contract: contract,
            id: id,
            custom: custom || false
        })

        const royalties = await getRoyalties(contract, custom)
        const data = await fetch(IPFS_GATEWAY+cleanHash(metadata.tokenURI))
        const retrievedMetadata = await data.json()
        const mimeType = IPFS_GATEWAY + `${retrievedMetadata.animation_url ? cleanHash(retrievedMetadata.animation_url) : cleanHash(retrievedMetadata.image)}`

        const structuredMetadata = { 
            "metadata": {
            "name": retrievedMetadata.name,
            "description": retrievedMetadata.description,
            "raw": {
                "contentType": await getMimeType(mimeType).then(res => res),
                "contentURI": retrievedMetadata.animation_url ? IPFS_GATEWAY+cleanHash(retrievedMetadata.animation_url) : IPFS_GATEWAY+cleanHash(retrievedMetadata.image)
            }
            },
            "nft": {
            "minted": {
                "address": metadata.artist
            },
            "drop": {
            "artist": metadata.artist,
            "curator": metadata.curator,
            "curationFee": metadata?.curationFee.toNumber(),
            "royalties": royalties,
            "supply": metadata?.supply.toNumber(),
            "balance": metadata?.balance.toNumber(),
            "price": ethers.BigNumber.from(metadata?.price),
            "alPrice": ethers.BigNumber.from(metadata?.alPrice || 0)
            },
            "contract": contract.toLowerCase(),
            "tokenId": id
        }
        }

        resolve(structuredMetadata)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
}

// Get metadata of drops deployed using VCACustomFixedPrice Contract: AL mint, bundles, etc.
export const getVCACustomFPMetadata = async({
    contract,
    id
}:{
    contract: string;
    id: number;
}) => {
    return new Promise(async (resolve, reject) => {
        try {
          // get drop details based on Drop ID
          const metadata = await getVCAFixedSaleDrop({
              contract: contract,
              id: id,
              custom: true
          })
  
          const royalties = await getRoyalties(contract)
          const data = await fetch(IPFS_GATEWAY+cleanHash(metadata.tokenURI))
          const retrievedMetadata = await data.json()
          const mimeType = IPFS_GATEWAY + cleanHash(retrievedMetadata.image)
          const structuredMetadata = { 
              "metadata": {
              "name": retrievedMetadata.name,
              "description": retrievedMetadata.description,
              "raw": {
                  "contentType": await getMimeType(mimeType).then(res => res),
                  "contentURI": IPFS_GATEWAY+cleanHash(retrievedMetadata.image)
              }
              },
              "nft": {
              "minted": {
                  "address": metadata.artist
              },
              "drop": {
              "artist": metadata.artist,
              "curator": metadata.curator,
              "curationFee": metadata?.curationFee.toNumber(),
              "royalties": royalties,
              "supply": metadata?.supply.toNumber(),
              "balance": metadata?.balance.toNumber(),
              "price": ethers.BigNumber.from(metadata?.price)
              },
              "contract": contract.toLowerCase(),
              "tokenId": id
          }
          }
  
          resolve(structuredMetadata)
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
}

// retrieves all the ETH tokens minted using VCA's creator mint function 
export const getMintedEthTokens = async(wallet: string) => {
    const ethProvider =  new ethers.providers.InfuraProvider("homestead", process.env.REACT_APP_INFURA_API_KEY);
    const contractInstance = new ethers.Contract(VCA_ETH_MINT_ADDRESS, EthMint, ethProvider);
    const numTokens = await contractInstance.balanceOf(wallet)

    let tokens = [];

    if (numTokens > 0) {
        for(let i = 0; i < numTokens; i++) {
            const tokenId = await contractInstance.tokenOfOwnerByIndex(wallet, i)
            const metadata = await contractInstance.tokenURI(tokenId)
            const data = await fetch(IPFS_GATEWAY+metadata)
            const retrievedMetadata = await data.json()
            tokens.push({...retrievedMetadata, tokenId: tokenId })
        }
    }
    
    return tokens
}

// Get Bundle details
export const getBundleDetails = async (contract: string, cryptoNetwork: string, bundleId: number) => {

    return new Promise(async (resolve, reject) => {
        try {
            const bundleDetailsFromDb = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.query.getBundle,
                variables: {
                    contract: contract,
                    cryptoNetwork: cryptoNetwork,
                    bundleId: bundleId
                }
            })

            const ethProvider =  new ethers.providers.InfuraProvider("homestead", process.env.REACT_APP_INFURA_API_KEY);
            const contractInstance = new ethers.Contract(contract, VCACustomFixedPrice.abi, ethProvider)
            const bundleDetailsFromContract = await contractInstance.bundleDetails(bundleId)
            const dropIdsInBundle = await contractInstance.getBundleDropIds(bundleId)

            const image = IPFS_GATEWAY+cleanHash(bundleDetailsFromDb.bundles[0].coverImage)
           
            let dropIdsArr = []
            let lowestBalance = 0

            for (let i = 0; i < dropIdsInBundle.length; i++) {
                const dropInfo = await contractInstance.dropDetails(parseInt(dropIdsInBundle[i]))

                if (i == 0) {
                    lowestBalance = dropInfo.balance.toNumber();
                } else {
                    if (lowestBalance > dropInfo.balance.toNumber()) {
                        lowestBalance = dropInfo.balance.toNumber()
                    }
                }

                dropIdsArr.push(parseInt(dropIdsInBundle[i]))
            }

            const royalties = await getRoyalties(contract, true)
            const artist = await contractInstance.artist()

            const structuredMetadata = {
                "name": bundleDetailsFromDb.bundles[0].name || "",
                "description": bundleDetailsFromDb.bundles[0].description || "",
                "dropIds": dropIdsArr,
                "royalties": royalties,
                "artist": artist,
                "balance": lowestBalance || 0,
                "price": ethers.BigNumber.from(bundleDetailsFromContract.price),
                "alPrice": ethers.BigNumber.from(bundleDetailsFromContract.alPrice) || 0,
                "saleStart": bundleDetailsFromDb.bundles[0].saleStart,
                "contentType": await getMimeType(image).then(res => res),
                "contentURI": image
            }

            resolve(structuredMetadata)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const objktHistory = `
    query queryHistoryVCA($contract: String = "", $id: String = "") {
        english_auction(where: {fa_contract: {_eq: $contract}, token: {token_id: {_eq: $id}}}, limit: 1, order_by: {timestamp: desc_nulls_last}) {
            bigmap_key 
            highest_bidder_address
            highest_bid
            end_time
            price_increment
            status
            start_time
            price_increment
            bids(order_by: { timestamp: desc_nulls_last }) {
                amount
                bidder_address
                id
                timestamp
                ophash
            }
            seller_address
            reserve
        }
    }  
`

const zoraHistory = `
    query queryHistoryVCA($contract: String, $id: String) {
        Auction(
            where: {
                tokenContract: { _eq: $contract },
                tokenId: { _eq: $id }
            }
        ) {
            auctionId
            status
            tokenOwner
            tokenId
            approved
            curator
            auctionId
            reservePrice
            duration
            firstBidTime
            expiresAt
            bidEvents(order_by: { blockTimestamp: desc_nulls_last }) {
                id
                blockTimestamp
                transactionHash
                value
                sender
            }
        }
    }
`

const query__swap__data = `
query querySwapData($fa2_contract: String!, $tokenId: String!, $vcaAddress: String!, $minterAddress: String!) {
    listing(where: {_and: [{fa_contract: {_eq: $fa2_contract}}, {token: {token_id: {_eq: $tokenId}}},{amount_left: {_gt: 0}},{ status: {_eq: "active"}}, {_or: [{seller_address: {_eq: $vcaAddress}}, {seller_address: {_eq: $minterAddress}}]}]}, order_by: {timestamp: desc}) {
        amount
        amount_left
        price
        bigmap_key
        token {
          display_uri
          name
          supply
        }
    }
    }  
`
const query__latest__swap__data = `
    query queryLatestSwapData($fa2_contract: String!, $tokenId: String!, $vcaAddress: String!, $minterAddress: String!) {
        listing(where: {_and: [{fa_contract: {_eq: $fa2_contract}}, {token: {token_id: {_eq: $tokenId}}},{amount_left: {_gt: 0}},{ status: {_eq: "active"}}, {_or: [{seller_address: {_eq: $vcaAddress}}, {seller_address: {_eq: $minterAddress}}]}]}, order_by: {timestamp: desc}) {
            amount
            amount_left
            price
            bigmap_key
            token {
              display_uri
              name
              supply
            }
        }
    }  
`


const query__token__data = `
    query queryTokenData($fa2_contract: String!, $tokenId: String!) {
        token(where: {_and: {fa_contract: {_eq: $fa2_contract}, token_id: {_eq: $tokenId}}}) {
            artifact_uri
            display_uri
            creators {
              creator_address
            }
            decimals
            fa_contract
            metadata
            mime
            token_id
            tags {
              tag {
                name
              }
            }
            description
            supply
            name
            royalties {
                amount
                receiver_address
                decimals
            }
          }
    }  
`
export const getHistory = ({
    network,
    contract,
    id
}: {
    network: "ethereum" | "tezos",
    contract: string,
    id: number
}) => {
    return new Promise (async (resolve, reject) => {
        const str = id.toString()

        try {
            if (network === "tezos") {
                const { data } = await axios.post('https://data.objkt.com/v3/graphql/', {
                    query: objktHistory,
                    variables: {
                        contract,
                        id: str
                    }
                })

                const history = data.data.english_auction[0]
                resolve(history)
            } else if (network === "ethereum") {
                const addr = await checksum(contract)

                const { data } = await axios.post('https://indexer-prod-mainnet.zora.co/v1/graphql', {
                    query: zoraHistory,
                    variables: {
                        contract: addr,
                        id: str
                    }
                })  

                const numCreated = data.data.Auction.length
                const history = data.data.Auction[numCreated-1]
                resolve(history)
            }
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const getSwaps = ({
    network,
    contract,
    id,
    minterAddress,
    vcaAddress
}: {
    network: "ethereum" | "tezos",
    contract: string,
    id: number,
    minterAddress: string,
    vcaAddress: string
}) => {
    return new Promise (async (resolve, reject) => {
        const str = id.toString()

        try {
            if (network === "tezos") {
                const data = await axios.post(`https://data.objkt.com/v3/graphql`, {
                    query: query__swap__data,
                    variables: {
                        fa2_contract: contract,
                        tokenId: str,
                        minterAddress: minterAddress,
                        vcaAddress: vcaAddress
                    },
                    operationName: 'querySwapData'
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const swaps = data.data.data.listing
                resolve(swaps)
            }
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const getSwap = ({
    network,
    contract,
    id,
    vcaXTZAddress,
    minterAddress
}: {
    network: "ethereum" | "tezos",
    contract: string,
    id: number,
    vcaXTZAddress: string,
    minterAddress: string
}) => {
    return new Promise (async (resolve, reject) => {
        const str = id.toString()

        try {
            if (network === "tezos") {   
                const data = await axios.post(`https://data.objkt.com/v3/graphql`, {
                    query: query__latest__swap__data,
                    variables: {
                        fa2_contract: contract,
                        tokenId: str,
                        vcaXTZAddress: vcaXTZAddress,
                        minterAddress: minterAddress
                    },
                    operationName: 'queryLatestSwapData'
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const swaps = data.data.data.listing
                resolve(swaps)
            }
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const cleanHash = (hash: string) => {
    try {
        if (hash.startsWith("ipfs://")) {
            return hash.substring(7)
        }
    
        const index = hash.indexOf("ipfs/")
        if (index > -1) {
            return hash.substring(index + 5)
        }    
    } catch (error) {
        return ""
    }

}

const queryFxhashSwaps = `
    query queryFxhashSwaps($id: Float) {
        generativeToken(id: $id) {
            id
            name
            supply
            balance
            thumbnailUri
            author {
                id
            }
            reserves {
                amount
            }
            pricingFixed {
                price
                opensAt
            }
            pricingDutchAuction {
                levels
                restingPrice
                decrementDuration
                opensAt
            }
        }
}`

export const getFxhashSwapData = (id: number) => {    
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(`https://api.fxhash.xyz/graphql`, {
                query: queryFxhashSwaps,
                variables: {
                    id,
                },
                operationName: 'queryFxhashSwaps'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            resolve(res.data.data.generativeToken)

        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

// Get Project Info from Artblocks API 
export const getAbProjectInfo = (id: number) => {
    
    return new Promise(async (resolve, reject) => {
        try {
           
          
          const ethProvider =  new ethers.providers.InfuraProvider("homestead", process.env.REACT_APP_INFURA_API_KEY);
          const coreAbi = await getAbi(ARTBLOCKS_FLEX_CONTRACT_ADDRESS)
          const abCoreEngine = new ethers.Contract(ARTBLOCKS_FLEX_CONTRACT_ADDRESS, coreAbi, ethProvider)
          const tokenDetails = await abCoreEngine.projectTokenInfo(id)
          const scriptDetails = await abCoreEngine.projectScriptInfo(id)
          
          const minterAbi = await getAbi(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS)
          const abMinter = new ethers.Contract(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS, minterAbi, ethProvider)
          const auctionDetails = await abMinter.projectConfig(id)
          const priceInfo = await abMinter.getPriceInfo(id)

          /**
          // Only display active projects
          if (tokenDetails.active) { 
            resolve({...projectDetails, ...tokenDetails, "royalties": royalties.toNumber()})
          **/

          let projectDetails = await fetch(`${ARTBLOCKS_TOKEN_ADDRESS}${id}000000`)
          console.log(projectDetails)
          projectDetails = await projectDetails.json()
         
          resolve({...projectDetails, ...tokenDetails, ...priceInfo, ...auctionDetails, ...scriptDetails})
          

        } catch (error) {
            console.log(error)
            reject(error)
        } 
    })  
}

// Get project shells (id) created under an ethereum address
export const getAbProjectShell = (wallet: string) => {
    
    return new Promise(async (resolve, reject) => {
        try {

            // Get projectIds from CMS
            const projectIds = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.query.queryProjectsByAddress,
                variables: {
                    ethereum: wallet
                }
            })

            const ethProvider =  new ethers.providers.InfuraProvider("homestead", process.env.REACT_APP_INFURA_API_KEY);
            const coreAbi = await getAbi(ARTBLOCKS_FLEX_CONTRACT_ADDRESS)
            const abCoreEngine = new ethers.Contract(ARTBLOCKS_FLEX_CONTRACT_ADDRESS, coreAbi, ethProvider)
            const minterAbi = await getAbi(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS)
            const abMinter = new ethers.Contract(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS, minterAbi, ethProvider)
            const projectShells = []
            
            for (let i = 0; i < projectIds.artblocksProjects.length; i++) {

                const projectId = projectIds.artblocksProjects[i].projectId
                const projectDetails = await abCoreEngine.projectDetails(projectId)
                const projectTitle = projectDetails.projectName
                const auctionDetails = await abMinter.projectConfig(projectId)
                
                const info = {"id": projectId, "title": projectTitle, ...auctionDetails}
                projectShells.push(info)
            }

            resolve(projectShells)

        } catch (error) {
            console.error(error)
            reject(error)
        }
    })
}

// Get artblocks token features
export const getAbTokenFeatures = (projectId: number, id: string) => {
    return new Promise(async (resolve, reject) => {
        try {           
          let projectDetails = await fetch(`${ARTBLOCKS_TOKEN_ADDRESS}${projectId}${id}`)
          projectDetails = await projectDetails.json()
         
          resolve(projectDetails)
          

        } catch (error) {
            console.log(error)
            reject(error)
        } 
    })  
}

// Get artblock individual token info
const queryAbTokenMint = `
query queryAbTokenMint($id: String) {
    token(id: $id) {
      owner {
        id
      }
      createdAt
    }
}`;

export const getAbTokenMintInfo = (id: string) => {    
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(ARTBLOCKS_GRAPHQL, {
                query: queryAbTokenMint,
                variables: {
                    id,
                },
                operationName: 'queryAbTokenMint'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            resolve(res.data.data.token)

        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const checkPermissions: any = {}

// checkPermissions.objkt = (address: string) => {
    
// }