import { Countdown, Label, LazyLoad } from '../design-system'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { ethers } from "ethers"
import { cleanHash, getFxhashSwapData, shorten } from '../../utils/crypto'
import { IPFS_GATEWAY } from '../../constants'
import { FXHASH_CONTRACT_ADDRESS } from '../../constants'
import { getDateTime } from '../../utils/time'
import { findXTZArtistName } from '../../components/token/token'
import { getHistory, getAbProjectInfo } from "../../utils/crypto"

const physicalPrints = require('../../constants/physical-prints.json')

export const getTokenInfo = ((id: number) => {
    const info = physicalPrints.find((entry: any) => entry.id === id)
    return info
})

export const DummyToken = ({
    id,
    to = "",
    metadata,
    swapdata,
    saleStart,
    saleEnd,
    saleType,
    additionalInfo,
    artist_name,
    title,
    edition,
    token_price,
    dropType,
    dropDate,
    contract,
    cryptoNetwork
} : {
    id: number;
    to: string;
    metadata: any;
    swapdata?: any;
    saleStart?: string;
    saleEnd?: string;
    saleType?: string;
    additionalInfo?: string;
    artist_name?: string;
    title?: string;
    edition?: string;
    token_price?: string;
    dropType?: string;
    dropDate?: string;
    contract?: string;
    cryptoNetwork: "ethereum" | "tezos",
}) => {
    const [ price, setPrice ] = useState<Number>(0);
    const [ balance, setBalance ] = useState<Number>(0);
    const [ coverImage, setCoverImage ] = useState<string>("");
    const [ artist, setArtist ] = useState<string>("");
    const [ swapData, setSwapData ] = useState<any>();
    const [ bidData, setBidData ] = useState<any>();
    const [project, setProject] = useState<any>({})
    
    const [ availability, setAvailability ] = useState<Number>(0);


    useEffect(() => {
        const queryABTokens = async () => {
            if (saleType && saleType.toLowerCase().includes("exhibition")) {
                if (dropType == "Art Blocks Engine") {
                 /*   try {
                        console.log("Art Blocks Engine")
                        const projectDetails: any = await getAbProjectInfo(parseInt(to.split('/')[6]))
                        console.log(projectDetails)
                        // Reconstruct object to correct types
                        setProject({
                            artist: projectDetails.artist,
                            artistAddress: projectDetails.royaltyInfo.artistAddress,
                            currency: 'ETH',
                            description: projectDetails.description,
                            invocations: projectDetails.invocations.toNumber(),
                            license: projectDetails.license || null,
                            maxInvocations: projectDetails.maxInvocations,
                            projectName: projectDetails.collection_name.split(`by ${projectDetails.artist}`)[0],
                            royalties: projectDetails.royaltyInfo.royaltyFeeByID || 0,
                            library: JSON.parse(projectDetails.scriptJSON).type || null,
                            aspectRatio: JSON.parse(projectDetails.scriptJSON).aspectRatio || 1,
                            saletype: ethers.utils.formatEther(projectDetails.basePrice) == ethers.utils.formatEther(projectDetails.startPrice) ? 'fixed' : 'auction',
                            auctionDetails: {
                            startPrice: parseFloat(ethers.utils.formatEther(projectDetails.startPrice)),
                            basePrice: parseFloat(ethers.utils.formatEther(projectDetails.basePrice)),
                            timeInterval: projectDetails.priceDecayHalfLifeSeconds.toNumber()/60
                            },
                            price: parseFloat(ethers.utils.formatEther(projectDetails.tokenPriceInWei)),
                            startTime: getDateTime(projectDetails.timestampStart.toNumber()*1000)
                        })
                    } catch (error) {
                    console.error(error)
                    }*/
                }
            }
        }
        queryABTokens();
    }, [])
    
    useEffect(() => {
        const queryFxhashTokens = async () => {
            if (saleType && saleType.toLowerCase().includes("exhibition")) {
                //obtain bids
                if (dropType == "fx(hash)") {
                    try {
                        const swapdata: any = await getFxhashSwapData(getTokenInfo(id).fxhashId)
                        
                        // Only set token availability when sale started, so its position is accurate
                        if(swapdata.pricingFixed && swapdata.pricingFixed.opensAt <= new Date().toISOString() ||  
                        swapdata.pricingDutchAuction && swapdata.pricingDutchAuction.opensAt <= new Date().toISOString()) {
                            
                            let reserveAmt = 0;

                            //obtain reserves
                            for (let i = 0; i < swapdata.reserves.length; i++) {
                                reserveAmt+= swapdata.reserves[i].amount;
                            }

                            let xavailability = swapdata.balance - reserveAmt
                            setAvailability(xavailability)
                        }

                        // set token availability to 0 if sale has not started so it's at the back
                        else {
                            let xavailability = 0
                            setAvailability(xavailability)
                        }

                        setSwapData(swapdata)
                        //if token has a fixed pricing, return this:
                        if (swapdata.pricingFixed) {
                            setPrice(swapdata.pricingFixed.price/1e6)
                        }

                        //if token is dutch auction
                        else if(swapdata.pricingDutchAuction) {
                            const { opensAt, levels, decrementDuration } = swapdata.pricingDutchAuction
                            // compute the time difference (in seconds) between opens and mint time
                            const timeDiff = (Date.now() - new Date(opensAt).getTime()) / 1000
                            // find the index of the level based on this time difference
                            const index = Math.min(
                            Math.floor(timeDiff / parseInt(decrementDuration)),
                            levels.length-1
                            )

                            // return corresponding level
                            setPrice(parseInt(levels[index])/1e6)
                        } 

                        let reserveAmt = 0;

                        //obtain reserves
                        for (let i = 0; i < swapdata.reserves.length; i++) {
                            reserveAmt+= swapdata.reserves[i].amount;
                        }

                        setBalance(swapdata.balance-reserveAmt)

    
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        }
        queryFxhashTokens();
    }, [])

    useEffect(() => {
        const queryTokenBids = async () => {
            if(dropType == "Auction"){
                //obtain bids
                if (contract) {
                    const history:any = await getHistory({
                        network: cryptoNetwork,
                        contract: contract,
                        id: parseInt(to.split('/')[6]),
                    })
                    setBidData(history)
                }
            }
        }
        queryTokenBids();
    }, [])

    useEffect(() => {
        const func = async () => {
            if (saleType && saleType.toLowerCase().includes("fxhash")) {
                // fxhash tokens
                if (id) {
                    try {
                        const swapdata: any = await getFxhashSwapData(id)

                        // Only set token availability when sale started, so its position is accurate
                        if(swapdata.pricingFixed && swapdata.pricingFixed.opensAt <= new Date().toISOString() ||  
                        swapdata.pricingDutchAuction && swapdata.pricingDutchAuction.opensAt <= new Date().toISOString()) {
                            
                            let reserveAmt = 0;

                            //obtain reserves
                            for (let i = 0; i < swapdata.reserves.length; i++) {
                                reserveAmt+= swapdata.reserves[i].amount;
                            }

                            let xavailability = swapdata.balance - reserveAmt
                            setAvailability(xavailability)
                        }

                        // set token availability to 0 if sale has not started so it's at the back
                        else {
                            let xavailability = 0
                            setAvailability(xavailability)
                        }

                        setSwapData(swapdata)
                        //if token has a fixed pricing, return this:
                        if (swapdata.pricingFixed) {
                            setPrice(swapdata.pricingFixed.price/1e6)
                        }

                        //if token is dutch auction
                        else if(swapdata.pricingDutchAuction) {
                            const { opensAt, levels, decrementDuration } = swapdata.pricingDutchAuction
                            // compute the time difference (in seconds) between opens and mint time
                            const timeDiff = (Date.now() - new Date(opensAt).getTime()) / 1000
                            // find the index of the level based on this time difference
                            const index = Math.min(
                            Math.floor(timeDiff / parseInt(decrementDuration)),
                            levels.length-1
                            )

                            // return corresponding level
                            setPrice(parseInt(levels[index])/1e6)
                        } 

                        let reserveAmt = 0;

                        //obtain reserves
                        for (let i = 0; i < swapdata.reserves.length; i++) {
                            reserveAmt+= swapdata.reserves[i].amount;
                        }

                        setBalance(swapdata.balance-reserveAmt)

                        setArtist(swapdata.author.id)
                        setCoverImage(IPFS_GATEWAY + cleanHash(swapdata.thumbnailUri))
                    } catch (error) {
                        console.log(error)
                    }
                }

                // physical print tokens
                else {
                    let xavailability = 0;
                    setAvailability(xavailability)
                }
            }
        }
        func();
    }, [])

    if (saleType && saleType.toLowerCase().includes("exhibition")) {
        return (
                <div className={styles.exhibition__token} onClick={() => null}>
                    <div className={styles.token__devider}>
                        <div className={styles.exhibition__edit__container}>
                            <img
                                //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                                alt="token"
                                className={styles.exhibition__token__cover}
                                src={saleType && saleType.toLowerCase().includes("fxhash") ? ("/token_covers/fxhash_" + id  + '.png') : getTokenInfo(id).coverUrl}
                            />
                        </div>
                    </div>
                    <div className={`${styles.token__devider} ${styles.dummy__token__info}`}>
                        <div className={styles.artist__title__box}>
                            <div className={styles.artist__info__box__name}>
                                { artist_name }
                            </div>
                            <div className={styles.artist__info__box__title}>
                                { title }
                            </div>
                        </div>
                        <br />
                        <br />

                        <div className={styles.token__info__box}>
                            <div className={styles.artist__info__box__two}>
                                {dropType == "Auction" ? (
                                    <>
                                    {bidData && (bidData.bidEvents.length || bidData.bids?.length > 0) ? (
                                        <>
                                        {cryptoNetwork === "ethereum" ? (
                                            (bidData.status === "ENDED" || (bidData.expiresAt &&  new Date(bidData.expiresAt) <= new Date())) ? "Sold For " :
                                            bidData.bidEvents?.length === 0 ? "Reserve Price" :
                                            bidData.bidEvents?.length > 0 && "Current Bid"
                                        ) : (
                                            bidData.status === "concluded" ? 
                                            (bidData.highest_bid === null ? "" : "Sold For") :
                                            bidData.bids?.length === 0 ? "Reserve Price" :
                                            bidData.bids?.length > 0 && "Current Bid"
                                        )}
                                        {cryptoNetwork === "ethereum" ? (
                                            (bidData.status === "ENDED" || (bidData.expiresAt && new Date(bidData.expiresAt) <= new Date())) ? bidData.bidEvents[0].value :
                                            bidData.bidEvents?.length === 0 ? bidData.reservePrice :
                                            bidData.bidEvents?.length > 0 && bidData.bidEvents[0].value) / 1e18 + " ETH"
                                         : (
                                            (bidData.highest_bid === null && bidData.status === "concluded") ? "In the auction house" :
                                            (bidData.status === "concluded" ? bidData.highest_bid :
                                            bidData.bids.length === 0 ? bidData.reserve :
                                            bidData.bids.length > 0 && bidData.highest_bid) / 1e6 + " XTZ")
                                        }
                                        </>
                                    ):(
                                        <>
                                        {token_price}
                                        </>
                                    )}
                                    </>
                                ):(
                                    <>
                                    {token_price}
                                    </>
                                )}
                            </div>
                            <div className={styles.artist__info__box__two}>
                                { edition }
                            </div>
                            <div className={styles.artist__info__box__two}>
                                { dropType }
                            </div>
                        </div>
                        <br />
                        <br />
                        { to == "https://vcanftgallery.verticalcrypto.art/" ? (
                            <>
                            <div className={styles.artist__info__box__two}>
                                {dropDate}
                            </div>
                            </>
                        ):(
                            <>
                            { dropType == "Art Blocks Engine" ? (
                                <>
                                <div className={styles.token__info__box}>
                                    <div className={styles.artist__info__box__two}>
                                        {project.maxInvocations} - {project.maxInvocations-project.invocations} left
                                    </div>
                                </div>
                                </>
                            ):(
                                null
                            )}
                            { dropType == "fx(hash)" ? (
                                <div className={styles.token__info__box}>
                                    {/** if not sold out */}
                                    { balance > 0 ? (
                                    /** Display fxhash token info if swap sale is ongoing **/
                                    
                                        <div className={styles.artist__info__box__two}>
                                            {swapData.supply} - {balance} left
                                            <div>
                                                {price} XTZ
                                            </div>
                                            
                                            <div className={styles.artist__info__box__two}>
                                                <a href={to} className={styles.dummy__buy__button}>
                                                View & Collect
                                                </a>
                                            </div>
                                        </div>
                                    ):(
                                    <div className={styles.artist__info__box__two}>
                                        <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                            Sold Out {balance}
                                        </div>   
                                        <a href={to} className={styles.dummy__buy__button}>
                                            View
                                        </a>
                                    </div>
                                    )}
                                </div>
                            ):(
                                <>
                                    <a href={to} className={styles.dummy__buy__button}>
                                        View & Collect
                                    </a>
                                </>
                            )}
                            </>
                        )}
                    </div>
                </div>
        )
    }
    if (saleType && saleType.toLowerCase().includes("sub-token")) {
        return (
                
            <a href={to} >
            <div className={styles.token} onClick={() => null}>
                <div className={styles.edit__container}>
                    <img
                        //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                        alt="token"
                        className={styles.event__cover}
                        src={saleType && saleType.toLowerCase().includes("fxhash") ? ("/token_covers/fxhash_" + id  + '.png') : getTokenInfo(id).coverUrl}
                    />
                </div>
                <div className={styles.artist__name_event_view}>                
                    { saleType && saleType.toLowerCase().includes("fxhash") ? findXTZArtistName(artist) : getTokenInfo(id).artist }
                    
                </div>
                <div className={styles.label__container}>
                    <Label>
                        { additionalInfo }
                    </Label>
                </div>
                <div className={styles.label__container}>
                    <Label>
                    {edition == "1" ? (
                        <>
                        Unique 1/1
                        </>
                    ):(
                        <>
                        { edition } Editions
                        </>
                    )}
                    </Label>
                    { to == "https://vcanftgallery.verticalcrypto.art/" ? (
                        <>
                            <Label>
                                { dropDate }
                            </Label>
                        </>
                    ):(
                        <>
                        <a href={to} className={styles.dummy__buy__button}>
                            View & Collect
                        </a>
                        </>
                    )
                    }
                </div>
                

            </div>
        </a>
        )
    }
    else {

        return (
            <a href={to} >
                <div className={styles.token} onClick={() => null}>
                    <div className={styles.edit__container}>
                        <img
                            //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                            alt="token"
                            className={styles.event__cover}
                            src={saleType && saleType.toLowerCase().includes("fxhash") ? ("/token_covers/fxhash_" + id  + '.png') : getTokenInfo(id).coverUrl}
                        />
                    </div>
                    <div className={styles.artist__name_event_view}>                
                        { saleType && saleType.toLowerCase().includes("fxhash") ? findXTZArtistName(artist) : getTokenInfo(id).artist }
                    </div>
                    <div className={styles.label__container}>
                        <Label>
                            { additionalInfo }
                        </Label>
                    </div>
                    {/** Display info for Physical PRINT tokens */}
                    { !saleType && getTokenInfo(id).type.toLowerCase().includes("print")  && (
                    <div className={styles.auction__history}>
                        <div className={styles.info__container}>
                            <div>
                                <Label>
                                    On Sale
                                </Label>
                                <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                    {getTokenInfo(id).price}
                                </div>
                            </div>
                            <a href={to} className={styles.buy__button}>
                                    Buy
                            </a> 
                        </div>
                            {getTokenInfo(id).endDate > new Date().toISOString() &&
                            (<div style={{ width: '100%' }}>
                                <Countdown target={new Date(getTokenInfo(id).endDate)} 
                                className={styles.countdown} />        
                            </div>)
                            }
                    </div>
                    )}
                    {/** Display info for fxhash tokens */}
                    { saleType && saleType.toLowerCase().includes("fxhash") && (
                    <div className={styles.auction__history}>
                        {/** if not sold out */}
                        { balance > 0 ? (
                        /** if swapped before swap sale start date, display available date instead **/
                        swapData.pricingFixed && swapData.pricingFixed.opensAt > new Date().toISOString() || 
                        swapData.pricingDutchAuction && swapData.pricingDutchAuction.opensAt > new Date().toISOString() ? 
                        (
                            <div className={styles.info__container}>
                            <div>
                                <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                Available on { swapData.pricingFixed && getDateTime(swapData.pricingFixed.opensAt) ||  
                                                swapData.pricingDutchAuction && getDateTime(swapData.pricingDutchAuction.opensAt) }
                                </div>
                            </div>
                            </div>
                        ):(
                        /** Display fxhash token info if swap sale is ongoing **/
                        <div className={styles.info__container}>
                            <div>
                                <Label>
                                    Edition of {swapData.supply} - {balance} left
                                </Label>
                                <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                    {price} XTZ
                                </div>
                            </div>
                            <a href={to} className={styles.buy__button}>
                                Collect
                            </a> 
                        </div>
                        )
                        ):(
                        <div className={styles.info__container}>
                            <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                Sold Out
                            </div>
                        </div>
                        )}
                    </div>
                    )}
                    {/** Display info for upcoming tokens -- Tokens that are not ready yet */}
                    { !saleType && getTokenInfo(id).type.toLowerCase().includes("upcoming")  && (
                    <div className={styles.auction__history}>
                        <div className={styles.info__container}>
                            <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                Available on { getDateTime(getTokenInfo(id).startDate) }
                            </div>
                        </div>
                    </div>
                    )}
    
                </div>
            </a>
        )
    }
}
