import { useEffect, useState } from "react"
import { Container, DropdownInput, Label, Modal, NumberInput, Loader, CustomPagination, DateInput, TimeInput } from "../../../components/design-system"
import { ethereumStore, getProvider, getSigner } from "../../../state/crypto/ethereumStore"
import { tezosStore } from "../../../state/crypto/tezosStore"
import { Input, Button } from "../../../components/design-system"
import { getEvents, IEvent } from '../../admin/manage-events'
import { waitSignerEthereum, waitSignerTezos } from "../../../libs/crypto/crypto"
import { addToken, publishToken } from '../../admin/manage-tokens/tokens'
import { notificationStore } from "../../../state/global/notificationStore"
import { useQuery, gql } from '@apollo/client';
import { fetchGraphcms, gql as gcmsQuery } from "../../../libs/graphcms"
import { Token } from "../../../components/token/token"
import { CleanToken } from "../../../components/token/cleantoken"
import { Masonry } from "@mui/lab"
import { Search } from "../../../components/search"
import {
        DEFAULT_VCA_TZ_ADDRESS
       } from "../../../constants"

import styles from './styles.module.scss'

interface Tokens {
    fa2_address: string;
    artifact_uri: string;
    token_id: string;
    minter_address: string;
    royalties_total: number;
  }     
  
  interface TokensData {
    tokens: Tokens[];
  }
  
  interface TokensVar {
    tezosAddress: string;
  }

  export interface ISwap {
    id: string;
    contract: string;
    creator: string;
    tokenid: number;
    artifact_uri?: any;
    amount: number;
    price: number;
    active: boolean;
    royalties: number;
}

const GET_USER_TEZOS_NFTS = gql`
    query QueryTokens($tezosAddress : String!) {
        tokens(where: {holdings: {_and: {amount: {_gt: "0"}, holder_address: {_eq: $tezosAddress}}}}, order_by: {minted_at: desc}) {
            fa2_address
            artifact_uri
            metadata_uri
            token_id
            name
            minter_address
            royalties_total
        }
    }
`;

// Retrieve upcoming events
export const getUpcomingEvents = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gcmsQuery.query.queryUnpublishedEvents
    }).then((response) => {
        const allEvents = response.events
        const currentDt = new Date().toISOString();
        const upcomingEvents = allEvents.filter((eventObj: any) => eventObj.startDate >= currentDt)
        set(allEvents)
    }).catch((error) => {
        console.log(error)
    })
}

export const addSwap = async ({ swap, type, eventId }: { swap: ISwap, type: "add" | "edit", eventId: string }) => {
    return new Promise<string>(async (resolve, reject) => {
        const query = type === "add" ? gcmsQuery.mutation.createSwap : gcmsQuery.mutation.updateSwap

        try {
            const data = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: query,
                variables: {
                    contract: swap.contract,
                    creator: swap.creator,
                    tokenid: swap.tokenid,
                    artifact_uri: swap.artifact_uri,
                    amount: swap.amount,
                    price: swap.price,
                    royalties: swap.royalties,
                    active: false,
                    eventId
                }
            })

            const id: string = type === 'add' ? data.createSwap.id : swap.id
            resolve(id)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const Swap = () => {

    const notifications = notificationStore()
    // Connected Eth / Tezos addresses
    const tezosState = tezosStore()
    const ethereumState = ethereumStore()

    const [id, setId] = useState('')

    const [events, setEvents] = useState<IEvent[]>([])
    const [selectedEvent, setSelectedEvent] = useState("")

    const [swapPrice, setSwapPrice] = useState(0)
    const [swapAmount, setSwapAmount] = useState(0)

    const [start, setStart] = useState(new Date().toISOString().split('.')[0].slice(0,-3))
    const [eventId, setEventId] = useState("")

    const [openAddSwap, setOpenAddSwap] = useState(false)
    const [contract, setContract] = useState("")
    const [creator, setCreator] = useState("")
    const [curator, setCurator] = useState("")
    const [artifact_uri, setArtifactURI] = useState("")
    const [tokenId, setTokenId] = useState("")
    const [tokenRoyalties, setTokenRoyalties] = useState(0)
    const [tokensToDisplay, setTokensToDisplay] = useState<Tokens[] | undefined>([])
        
    const [page, setPage] = useState(1);

    // Retrieve selected event ID
    const getEventDetails = async (name: string, setEventId: any) => {

        await fetchGraphcms({
            key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
            query: gcmsQuery.query.queryEventByName,
            variables: {
                name,
            }
        }).then(async (response: any) => {
            const event = response.events?.[0]
            let eventId = event?.id

            // set variables in useState
            setEventId(eventId);
            setCurator(event.account?.tezos)
            setStart(event.startDate?.slice(0,-9))

        }).catch((error: any) => {
            console.log(error)
        })
    }

    const publishSwap = async (id: string) => {
        //update db
        return new Promise(async (resolve, reject) => {
            try {
                const res = await fetchGraphcms({
                    key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                    query: gcmsQuery.mutation.publishSwap,
                    variables: {
                        id,
                    }
                })

                resolve(res)

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    const { loading, error, data } = useQuery<TokensData, TokensVar>
    (GET_USER_TEZOS_NFTS, {
        variables: { tezosAddress: tezosState.address },
      });

    const createSwap = async () => {

        const notifID = notifications.addNotification({
            message: "Swap Token: ",
            status: "pending"            
        })

        try {

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Confirm Swap...',
            })

            // Interact with contract
            if (!tezosState?.signer) {
                await waitSignerTezos()
            }
            const Tezos = tezosState.provider

            const TOKEN_CONTRACT = await Tezos.wallet.at(contract);

            let fromAddress = tezosState.address;

            let op_confirm = await TOKEN_CONTRACT.methods.transfer([{ from_: fromAddress, txs: [{to_: curator, token_id: tokenId, amount: swapAmount}]}]).send()

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Add Swap to db',
            })

            //Write Swap to DB
            const res = await addSwap({
                swap: {
                    id: "",
                    contract: contract,
                    creator: creator,
                    tokenid: parseInt(tokenId),
                    artifact_uri: artifact_uri,
                    amount: swapAmount,
                    price: swapPrice,
                    royalties: (tokenRoyalties/100),
                    active: false
                },
                type: 'add',
                eventId: eventId
            })
            notifications.setNotificationMessage({
                id: notifID,
                message: 'Publishing Swap',
            })
            //Publish Swap
            await publishSwap(res)

            notifications.setNotificationMessage({
                id: notifID,
                message: "Swap Created"
            })
            
            // Add token to specified event in the database
            const data = await addToken({
                token: {
                    id: "",
                    tokenId: parseInt(tokenId),
                    cryptoNetwork: "tezos",
                    contract,
                    saleType: "swap",
                    saleStart: start+':00Z'
                },
                type: 'add',
                eventId: eventId
            })

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Publishing Token',
            })

            await publishToken(data)

            notifications.resolve(notifID)

            return await op_confirm.confirmation(2)

            } catch (error) {
                console.log(error)
                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Create Swap Failed",
                })    
                notifications.reject(notifID)
        }
    }

    useEffect(() => {
        if (!tezosState.address) return
        
        // Load existing events
        getUpcomingEvents(setEvents)

        // Load the first 20 tokens
        setTokensToDisplay(data?.tokens)

    }, [, tezosState.address, data])

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    // Display error if no wallet is connected
    if (!tezosState.address && !ethereumState.address) {
        return (
            <Container>
                <div className={styles.message}>
                    Please connect a wallet to continue.
                </div>
            </Container>
        )
    }

     // Display error if no Tezos Wallet connected
     if (!tezosState.address) {
        return (
            <Container>
                <div className={styles.message}>
                    We only support Tezos Tokens for now. Please connect your Tesoz wallet to continue.
                </div>
            </Container>
        )
    }

    if (loading) {
        console.log("Still fetching NFTS...")
    }
    
    if (error) {
        console.log("Error fetching NFTS...")
    }

    return (
        <Container>             
            <h1 style={{ marginBottom: '1em'}}>Create a Swap</h1>

            <Search options={["token_id", "name", "fa2_address"]}
                    placeholder="Search by token id, contract address or token description..."
                    allData={data?.tokens}
                    setNewData={setTokensToDisplay}
                    resetPage={setPage}
                    />

            { tokensToDisplay && 
                <>
                <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                    { tokensToDisplay.slice((page-1)*20, ((page-1)*20)+20).map(token => (
                        
                        <CleanToken
                        network="tezos"
                        contract={token.fa2_address}
                        id={parseInt(token.token_id)}
                        key={token.fa2_address + token.token_id.toString()}

                        onClick={() => {
                            // set params required by OBJKT API 
                            setTokenId(token.token_id || "0")
                            setContract(token.fa2_address || "")
                            setCreator(token.minter_address || "")
                            setArtifactURI(token.artifact_uri || "")
                            setTokenRoyalties(token.royalties_total)
                            setOpenAddSwap(true)
                        }}>
                        </CleanToken>
                    ))
                    }
                </Masonry>

                <CustomPagination 
                    onChange={handlePageChange}
                    page={page}
                    itemsToDisplay={tokensToDisplay} 
                    itemAmountToDisplay={20} />
                </>
            }
            <Modal
                open={openAddSwap}
                onClose={() => {
                    setOpenAddSwap(false)
                }}
                title="Add token to swap"
                >

                <Modal.Wrapper key="event">
                    <Label>Event</Label>

                    <DropdownInput
                        list={(events.map((event: IEvent) => { return event.name }))}
                        value={selectedEvent}
                        onChange={(event) => {
                            getEventDetails(event, setEventId).then(() => {
                                setSelectedEvent(event)
                            })
                        }}
                    >
                    </DropdownInput>
                </Modal.Wrapper>

                <Modal.Spacer key="spacer-0"/>

                <Modal.Wrapper key="start">
                    <Label>Sale Start Date and Time (UTC)</Label>
                    <div className={styles.date__container}>
                        <DateInput
                            value={start.split('T')[0]}
                            onChange={(e) => {
                                setStart(e.target.value + 'T' + start.split('T')[1])
                            }}
                            placeholder="Select Start Date"
                            className={styles.picker__input}
                        />

                        <TimeInput
                            value={start.split('T')[1].split('+')[0]}
                            onChange={(e) => {
                                setStart(start.split('T')[0] + 'T' + e.target.value)
                            }}
                            className={styles.picker__input}
                        />
                    </div>
                </Modal.Wrapper>

                
                <Modal.Wrapper key="price_swap">
                <Label>Price per edition</Label>

                <NumberInput
                    value={swapPrice}
                    onChange={(e) => setSwapPrice(parseFloat(e.target.value))}
                    placeholder={`eg. 1, 2, 3... XTZ`}

                    min={0.1}
                    step={0.1}
                />
                </Modal.Wrapper>

                <Modal.Spacer />
                <Modal.Wrapper key="amount_swap">
                <Label>Amount</Label>

                <NumberInput
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(parseFloat(e.target.value))}
                    placeholder={`eg. 1, 2, 3...`}

                    min={1}
                    step={1}
                />
                </Modal.Wrapper>

                <Modal.Spacer />
                <Modal.Wrapper>
                    <Button
                        onClick={createSwap}
                    > Confirm Swap
                    </Button>
                </Modal.Wrapper>
            </Modal>

        </Container>
    )
}