import { motion } from "framer-motion"
import { Clearance } from "../../../../containers/clearance"

import { Dropdown } from "../../../design-system"

import styles from './dropdown.module.scss'

interface INetworks {
    name: string
    address: string
    changeAccounts: () => void
}

type NetworkIcon = { name: string, icon: React.ReactNode }
type NetworkIcons = NetworkIcon[]

const networkIcons: NetworkIcons = [
    {
        name: 'Ethereum',
        icon: (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40" xmlSpace="preserve">
                <path d="M28.7,20.3L19.4,5v11.1l8.2,3.7l-8.2-3.7v9.6L28.7,20.3L28.7,20.3L28.7,20.3L28.7,20.3L28.7,20.3z M19.4,16.1V5L10,20.3l0,0
                l0,0l0,0l0,0l9.4,5.4V16.1l-8.2,3.7L19.4,16.1z M19.4,27.5V35L10,22L19.4,27.5z M19.4,27.5V35l9.4-13L19.4,27.5z"/>
            </svg>

        )
    },
    {
        name: 'Tezos',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 193 237" xmlSpace="preserve">
                <path d="M175.8,171.2c-2.3-40.7-53.5-59.2-58.4-61.1c-0.2-0.1-0.2-0.3-0.1-0.5l52.8-53v-5.7c0-0.9-0.8-1.7-1.7-1.7H71.1V17.3l0,0  V7.7l-35.9,7.5v5.4h2c0,0,8.8,0,8.8,8.8v19.7H18.2c-0.5,0-1,0.5-1,1v12.5h28.9c0,0,0,13.3,0,28.8v39.1c0,21.3,13.7,36.1,37.7,34.1  c5.1-0.4,9.8-2.4,13.8-5c1.8-1.2,2.9-3.1,2.9-5.3v-6.7c-7.8,5.2-14.4,4.9-14.4,4.9c-15.2,0-14.9-19.3-14.9-19.3V62.6h70l-50.4,50.8  c-0.1,6.7-0.2,11.9-0.2,12c0,0.2,0.1,0.3,0.3,0.3c46.2,7.8,58.7,37.7,58.7,46.4c0,0,5,42.3-37.3,45.2c0,0-27.7,1.2-32.6-9.9  c-0.2-0.4,0-0.8,0.4-1c4.6-2.1,7.7-6.2,7.7-11.7c0-8.2-5-14.9-15.5-14.9c-8.5,0-15.5,6.7-15.5,14.9c0,0-4,35.6,55.4,34.6  C180,228.1,175.8,171.2,175.8,171.2z"/>
            </svg>
        )
    }
]

const shortenAddress = (address: string) => {
    return address.slice(0, 6) + '...' + address.slice(-4)
}

export const HeaderDropdown = ({
    isOpen,
    networks,
}: {
    isOpen: boolean,
    networks: INetworks[]
}) => {
    const variants = {
        initial: {
            opacity: 1
        },

        hover: {
            scale: 1.1,

            transition: {
                duration: 0.1,
                delay: 0.05
            }
        },

        tap: {
            scale: 0.95,
        }
    }

    const changeAccountsVariants = {
        initial: {
            opacity: 0,
        },
        
        hover: {
            opacity: 1,
            scale: 1.1,

            transition: {
                duration: 0.1,
                delay: 0.05
            }
        },

        tap: {
            opacity: 0,
            scale: 0.95,
        }
    }

    const addressVariants = {
        initial: {
            opacity: 1,
            y: 0,
        },

        hover: {
            opacity: 0,
            y: 10,

            transition: {
                duration: 0.1,
                delay: 0.05
            }
        },

        tap: {
            opacity: 0,
        }
    }

    return (
        <Dropdown.Body isOpen={isOpen} className={styles.dropdown__container} key={"header-dropdown-body"}>
            <Dropdown.Items className={styles.profile__dropdown} key={"header-dropdown-items"}>
                {/* <Clearance required={1}> */}
                    {/* <Clearance required={2}> */}
                        <Dropdown.ItemLink to="/admin/dashboard" key={"header-/admin/dashboard"}>
                            Admin Panel
                        </Dropdown.ItemLink>
                    {/* </Clearance> */}
                        <Dropdown.ItemLink to="/creator/dashboard" key={"header-/admin/creator"}>
                            Creator Panel
                        </Dropdown.ItemLink>

                    <Dropdown.Spacer key={"header-dropdown-spacer-1"}/>
                {/* </Clearance> */}
                
                <Dropdown.ItemLink to="/wallets" key={"header-/wallets"}>
                    Wallet Manager
                </Dropdown.ItemLink>

                {networks.map((network) => (
                    <motion.div
                        whileHover="hover"
                        whileTap="tap"
                        initial="initial"

                        key={`container${network.name}`}
                    >

                        <Dropdown.Item
                            key={network.name}
                            className={styles.network__item}

                            onClick={() => {
                                network.changeAccounts()
                            }}
                        >
                                <div className={styles.network__container} key={`header-dropdown-icon-${network.name}`}>
                                    <div className={styles.network__icon}>
                                        {networkIcons.find((icon) => icon.name === network.name)?.icon}
                                    </div>
                                </div>

                                <motion.div
                                    className={styles.network__text}
                                    variants={variants}

                                    key={`header-dropdown-text-${network.name}`}
                                >
                                    {network.name}
                                </motion.div>
                                
                                <div className={styles.account__container} key={`header-network-container-${network.name}`}>
                                    <motion.div
                                        className={styles.change__account__text}
                                        variants={changeAccountsVariants}

                                        key={`header-dropdown-change-accounts-${network.name}`}
                                    >
                                        Click to Change
                                    </motion.div>

                                    <motion.div
                                        variants={addressVariants}

                                        key={`header-dropdown-address-${network.name}`}
                                    >
                                        {shortenAddress(network.address)}
                                    </motion.div>
                                </div>
                        </Dropdown.Item>
                    </motion.div>
                ))}
            </Dropdown.Items>
        </Dropdown.Body>
    )
}