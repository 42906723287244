import { useState, useEffect } from 'react'
import { ethers } from "ethers"


import { ARTBLOCKS_ARTIST_PROJECT_SITE } from '../../../constants'

import { Label, Modal, Button, TimeInput, DateInput } from '../../../components/design-system'
import styles from '../../admin/manage-users/styles.module.scss'
import { notificationStore } from '../../../state/global/notificationStore'
import { NumberInput } from '../../../components/design-system/inputs/inputs'
import { getAbProjectShell } from '../../../utils/crypto'
import { ethereumStore } from '../../../state/crypto/ethereumStore'
import { PageWrapper } from '../../../components/design-system'
import { waitSignerEthereum } from '../../../libs/crypto/crypto'
import { getSigner } from '../../../state/crypto/ethereumStore'
import { getAbi } from '../../../utils/crypto'
import { ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS } from '../../../constants'
import { getProvider } from '../../../state/crypto/ethereumStore'
import { Loader } from '../../../components/design-system'

export const ProjectShell = () => {
    const notifications = notificationStore()
    const ethereumState = ethereumStore()

    const [abProjectShells, setAbProjectShells] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [cachedProjectShells, setCachedProjectShells] = useState<any[]>([])

    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [saleType, setSaleType] = useState<'auction' | 'fixedprice'>('auction')
    const [start, setStart] = useState(new Date().toISOString().split('.')[0].slice(0,-3))
    const [decayInterval, setDecayInterval] = useState<number>(5)
    const [startPrice, setStartPrice] = useState(1.0)
    const [basePrice, setBasePrice] = useState(0.1)

    const [openEditModal, setOpenEditModal] = useState(false)

    const getProjectShells = async (wallet: string) => {
        const projectShellsInfo:any = await getAbProjectShell(wallet)
        setLoading(false)
        setAbProjectShells(projectShellsInfo)
        setCachedProjectShells(projectShellsInfo)
    }

    useEffect(() => {
        if (!ethereumState.address) return

        getProjectShells(ethereumState.address)
    }, [, ethereumState.address])

    const handleOpenEditModal = async (project: any, idx: number) => {
        setOpenEditModal(true)
        setCurrentIndex(idx)

        // If any of auction details have been set and 
        // we are just updating,
        // Set form values as currently set auction values
        if(project.timestampStart != 0 ) {
            // set sale type
            if(ethers.BigNumber.from(project.startPrice) !== ethers.BigNumber.from(project.basePrice)) {
                setSaleType('auction')
            } else {
                setSaleType('fixedprice')
            }

            // set date start
            setStart(new Date(project.timestampStart*1000).toISOString().split('.')[0].slice(0, -3))

            // set decayInterval
            setDecayInterval(project.priceDecayHalfLifeSeconds.toNumber()/60)

            // set start price
            setStartPrice(parseFloat(ethers.utils.formatEther(project.startPrice)))

            // set base price
            setBasePrice(parseFloat(ethers.utils.formatEther(project.basePrice)))
        }

    }

    const handleCloseEditModal = () => {
        setOpenEditModal(false)
        
        // reset values to base
        setCurrentIndex(0)
        setSaleType("auction")
        setStart(new Date().toISOString().split('.')[0].slice(0,-3))
        setDecayInterval(5)
        setStartPrice(1.0)
        setBasePrice(0.1)
    }

    const handleEditProjectShell = async() => {

        // Convert all values to function inputs
        const startTimestamp = (new Date(start+':00.000Z')).getTime()/1000
        const priceDecayHalfLifeSeconds = saleType == 'auction' ? decayInterval*60 : 300
        console.log(priceDecayHalfLifeSeconds)
        const saleStartPrice =  saleType == 'auction' ? ethers.utils.parseEther(startPrice.toString()) : ethers.utils.parseEther(basePrice.toString()) 
        const saleBasePrice = ethers.utils.parseEther(basePrice.toString())


        const notifId = notifications.addNotification({
            message: 'Updating sale details...',
            status: 'pending',
          })
        
          try {
              const signer = getSigner()
        
              if (!signer) {
                  await waitSignerEthereum(window)
              }

              const abi = await getAbi(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS)
              const abMinter = new ethers.Contract(ARTBLOCKS_DA_MINTER_CONTRACT_ADDRESS, abi, signer)
              const provider = getProvider()
    
              try {
                // Update project shell config
                const tx = await abMinter.setAuctionDetails(
                                cachedProjectShells[currentIndex].id, 
                                startTimestamp, 
                                priceDecayHalfLifeSeconds,
                                saleStartPrice,
                                saleBasePrice
                                )
    
                await provider.waitForTransaction(tx.hash);
    
                // Get the transaction receipt
                const receipt = await provider.getTransactionReceipt(tx.hash);
    
                // Check the status of the transaction
                if (receipt.status === 1) {
                    notifications.setNotificationMessage({
                      id: notifId,
                      message: "Successfully updated sale details"
                    })
            
                    notifications.resolve(notifId)

                    // Update cached info
                    cachedProjectShells[currentIndex].timestampStart = startTimestamp*1000
                    cachedProjectShells[currentIndex].priceDecayHalfLifeSeconds = priceDecayHalfLifeSeconds
                    cachedProjectShells[currentIndex].startPrice = saleStartPrice
                    cachedProjectShells[currentIndex].basePrice = saleBasePrice
    
                    handleCloseEditModal()
    
                } else {
                  // Catch any errors
                  notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error updating details"
                  })
    
                  notifications.reject(notifId)
                }
                
              } catch(error) {
                // Catch any errors
                console.error(error);
    
                notifications.setNotificationMessage({
                  id: notifId,
                  message: "Error updating details"
                })
    
                notifications.reject(notifId)
              }
    
            } catch(error) {
              
              console.log(error)
    
              notifications.setNotificationMessage({
                  id: notifId,
                  message: "Error updating details"
              })
    
              notifications.reject(notifId)
    
            }
        

    }

    return (
        loading ? (
            <Loader />
        ) : (
        <PageWrapper>
            <div className={styles.card__container}>
                <div className={styles.user__table__container}>
                    <table className={styles.user__table}>
                        <thead>
                            <tr>
                              <th>ID</th>
                              <th style={{ padding: '0 2rem' }}>Project name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cachedProjectShells.map((project: any, idx: number) => (
                                <tr key={project.id}>
                                    <td style={{ padding: '0 2rem' }}>{project.id}</td>
                                    <td><a style={{ textDecoration: "underline"}} href={ARTBLOCKS_ARTIST_PROJECT_SITE + project.id} target="_blank" rel="noreferrer">{project.title || "Undefined"}</a></td>

                                    { (project.timestampStart == 0 || project.timestampStart > Date.now()/1000 ) ? 
                                    (<td>
                                        <Button onClick={() => handleOpenEditModal(project, idx)}>Configure</Button>
                                    </td>) :  
                                    (<td>
                                        Sale started
                                    </td>)
                                    }
                                </tr>                                     
                            ))}
                        </tbody>
                    </table>
                </div>

                <Modal
                    onClose={() => {
                        handleCloseEditModal()
                    }}
                    open={openEditModal}
                    title="Update sale details"
                >
                    <div className={styles.add__user__modal}>
                        <div>
                            <div>
                                <Modal.Wrapper key="project-sale-type">
                                    <div className={styles.field}>
                                        <Label>Sale type</Label>
                                        <div className={styles.multiselect__container}>
                                            <Button className={`${styles.multiselect__button} ${saleType === "auction" ? styles.active : null}`} onClick={() => setSaleType("auction")} style={{ width: "49.5%" }}>
                                                Dutch Auction
                                            </Button>
                                            <Button className={`${styles.multiselect__button} ${saleType === "fixedprice" ? styles.active : null}`} onClick={() => setSaleType("fixedprice")} style={{ width: "49.5%" }}>
                                                Fixed price
                                            </Button>
                                        </div>
                                    </div>
                                </Modal.Wrapper>   

                                <Modal.Wrapper key="project-start">
                                    <Label>Sale Start Date and Time (UTC)</Label>
                                    <div className={styles.date__container} style={{ display: "flex" }}>
                                        <DateInput
                                            value={start.split('T')[0]}
                                            onChange={(e) => {
                                                setStart(e.target.value + 'T' + start.split('T')[1])
                                            }}
                                            placeholder="Select Start Date"
                                            className={styles.picker__input}
                                        />

                                        <TimeInput
                                            value={start.split('T')[1].split('+')[0]}
                                            onChange={(e) => {
                                                setStart(start.split('T')[0] + 'T' + e.target.value)
                                            }}
                                            className={styles.picker__input}
                                        />
                                    </div>
                                </Modal.Wrapper>
                                
                                { /** Additional details needed for auctions */}
                                { saleType == 'auction' && (
                                <>
                                <Modal.Wrapper key="project-time-decay">
                                    <Label>Time interval before current price is halved (in minutes)</Label>
                                    <NumberInput
                                        value={decayInterval}
                                        onChange={(e) => setDecayInterval(parseInt(e.target.value))}
                                        placeholder={`eg. 5, 15, 30, etc.`}

                                        min={5}
                                        step={1440}
                                    />
                                </Modal.Wrapper>

                                <Modal.Wrapper key="project-start-price">
                                    <Label>Start price (ETH)</Label>
                                    <NumberInput
                                        value={startPrice}
                                        onChange={(e) => setStartPrice(parseFloat(e.target.value))}
                                        placeholder={`eg. 0.1, 0.2, 0.3`}

                                        min={0.1}
                                        step={0.1}
                                    />
                                </Modal.Wrapper>
                                </>) }

                                <Modal.Wrapper key="project-start-price">
                                    <Label>{ saleType == 'auction' ? "Base price (ETH)" : "Price (ETH)" }</Label>
                                    <NumberInput
                                        value={basePrice}
                                        onChange={(e) => setBasePrice(parseFloat(e.target.value))}
                                        placeholder={`eg. 0.1, 0.2, 0.3`}

                                        min={0.1}
                                        step={0.1}
                                    />
                                </Modal.Wrapper>

                                <Modal.Spacer key={"project-shell-modal-spacer-1"}/>

                                 
                            </div>

                            <div className={styles.add__user__modal__buttons} key="project-shell-modal-submit">
                                <Button className={styles.add__modal__button} onClick={() => handleEditProjectShell()}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        </PageWrapper>
        )
    )
}