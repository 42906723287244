import classNames from "classnames";
import { useRef, useState, useEffect } from "react";

import styles from './styles.module.scss'

export const Countdown = ({
    target,
    className,
    children,
    ...props
}: {
    target: Date,
    className?: string;
    children?: React.ReactNode;
    [key: string]: any;
}) => {
    let interval: any = useRef()

    const [timerDays, setTimerDays] = useState('00')
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');

    const startTimer = (target: Date) => {
        const countdownDate = new Date(target).getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                // stop countdown
                clearInterval(interval.current);
            } else {
                setTimerDays(days.toString())
                setTimerHours(hours.toString());
                setTimerMinutes(minutes.toString());
                setTimerSeconds(seconds.toString());
            }
        }, 1000);
    };

    useEffect(() => {
        startTimer(target)

        return () => {
            clearInterval(interval)
        };
    });

    return (
        <div className={classNames(styles.contdown, className)} {...props}>
            Time Left:&nbsp;{timerDays}d {timerHours}h {timerMinutes}m {timerSeconds}s

            {children}
        </div>
    )
}