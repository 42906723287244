import styles from './styles.module.scss'
import { Label, Button } from '../../components/design-system';
import { useEffect, useState } from "react"
import { cleanHash } from '../../utils/crypto';
import { ARTBLOCKS_FLEX_CONTRACT_ADDRESS, ARTBLOCKS_GENERATOR_ADDRESS, ARTBLOCKS_IMAGE_PREVIEW, ARTBLOCKS_OPENSEA_LINK, IPFS_GATEWAY } from '../../constants';
import ImageIcon from '@mui/icons-material/Image';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import WaterIcon from '@mui/icons-material/Water';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import { getAbTokenMintInfo } from '../../utils/crypto';
import { getDateTime } from '../../utils/time';
import { shorten } from '../../utils/crypto';

export const TokenSaleInfoBox = ({
    network,
    supply,
    balance,
    price,
    royalties,
    license,
    library,
    type = 'fixed',
    auctionDetails,
    releaseinfos
}:{
    network: "ethereum" | "tezos";
    supply: number;
    balance: number;
    price: number;
    royalties: number;
    license?: string;
    library?: string;
    type?: string;
    auctionDetails?: any;
    releaseinfos?: string;
}) => {
    return(
        <>
        <div className={styles.editions__info}>
            <div className={styles.editions__info__box}>
                <Label>{type == 'auction' && network == 'ethereum' ? 'Linear Dutch Auction' : 'Edition Price'}</Label>
                { (type == 'auction' && network == 'ethereum' && auctionDetails) ?                               
                (<>
                <div style={{ paddingLeft: "0.5rem", marginBottom: "1rem" }}>
                    <h2>{`${auctionDetails.startPrice} -> ${auctionDetails.basePrice} ETH`}</h2>
                    <span className={styles.da_label}>Price decrease continuously and halves every {auctionDetails.timeInterval} minutes.</span>
                </div>

                <Label>Current price</Label>
                <h2 style={{ paddingLeft: "0.5rem"}}>{`${price/1e18} ETH`}</h2>
                </>
                ):
                (<h2 style={{ paddingLeft: "0.5rem"}}>{ network == "tezos" ? (price/1e6 + " XTZ") : (price/1e18 + " ETH")}</h2>)
                }
            </div>
            <div className={styles.editions__info__box}>
                <Label>Availability</Label>
                <h2 style={{ paddingLeft: "0.5rem", marginBottom:"1rem"}}>{balance} of {supply}</h2>
                
                <Label>Released on</Label>
                <h2 style={{ paddingLeft: "0.5rem"}}>{releaseinfos}</h2>
            </div>
        </div>{ /*
        <div className={styles.royalties__info__box}>   
            <Label>
                Royalties {royalties} %
            </Label>                          
            </div> */}
        </>
    )
}

export const TokenExternalLinks = ({
    network,
    contract,
    id,
    ipfsImage,
    balance
}:{
    network: "ethereum" | "tezos";
    contract: string;
    id: number;
    ipfsImage?: string;
    balance: number;
}) => {
    return(
        <div className={styles.links}>
            { network == "tezos" ?
            (
            <>
            <div className={styles.link}>
                <a className={styles.link__container} href={`https://objkt.com/asset/${contract}/${id}`} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on OBJKT</Label>
                </a>
            </div>
            <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                <a className={styles.link__container} href={`https://tzkt.io/${contract}/tokens/${id}`} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on TZKT</Label>
                </a>
            </div>
            </>
            ):(
            <>
            {balance != 0 ? (
                null
            ):(
                <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                    <a className={styles.link__container} href={`https://opensea.io/assets/ethereum/${contract}/${id}`} target="_blank" rel='noopener noreferrer'>
                        <Label center className={styles.link__label}>View on OpenSea</Label>
                    </a>
                </div>
            )}
              <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                <a className={styles.link__container} href={`https://etherscan.io/address/${contract}`} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on Etherscan</Label>
                </a>
              </div>
            </>
            )}
            { ipfsImage && (
            <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                <a className={styles.link__container} href={IPFS_GATEWAY + cleanHash(ipfsImage)} target="_blank" rel='noopener noreferrer'>
                    <Label center className={styles.link__label}>View on IPFS</Label>
                </a>
            </div>
            )}

        </div>
    )
}

// Links for AB specific tokens
export const ArtBlockLinks = ({
    displayedId,
    projectId
}:{
    displayedId: string,
    projectId: number
}) => {
    return (
        <>
        <div className={styles.link}>
          <a className={styles.link__container} href={ARTBLOCKS_OPENSEA_LINK+projectId.toString()+displayedId} target="_blank" rel='noopener noreferrer'>
            <Tooltip title="View on OpenSea">
                <WaterIcon 
                    className={styles.icon__style}
                />
            </Tooltip>
          </a>
        </div>
        <div className={styles.link}>
          <a className={styles.link__container} href={ARTBLOCKS_IMAGE_PREVIEW+projectId.toString()+displayedId+'.png'} target="_blank" rel='noopener noreferrer'>
            <Tooltip title="View Image">
                <ImageIcon 
                    className={styles.icon__style}
                />
            </Tooltip>
          </a>
        </div>
        <div className={styles.link}>
          <a className={styles.link__container} href={ARTBLOCKS_GENERATOR_ADDRESS+projectId.toString()+displayedId} target="_blank" rel='noopener noreferrer'>
            <Tooltip title="View Live View">
                <AspectRatioIcon 
                    className={styles.icon__style}
                />
            </Tooltip>
          </a>
        </div>
    </>
    )
}

// Links for AB specific tokens
export const ArtBlockLicenseAndLibrary = ({
    license,
    library
}:{
    license: string,
    library: string
}) => {
    return (
        <>
        <div className={styles.links}>
        {(license && library) && (
            <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)' }}>
                <Label center className={styles.link__label}>License</Label>
                <h3>{license}</h3> 
            </div>
        )}
        {(license && library) && (
            <div className={styles.link} style={{ borderLeft: '1px solid var(--f40)', borderRight: '1px solid var(--f40)' }}>
                <Label center className={styles.link__label}>Library</Label>
                <h3>{library}</h3> 
            </div>
        )}
        </div>
    </>
    )
}

// Infos for AB specific tokens - Display Notes
export const ArtBlockDisplayNotes = () => {
    const [artBlockNotes, setArtBlockNotes] = useState<boolean>(false)

    const openNotes = () => {
        setArtBlockNotes(!artBlockNotes);
    }
    return (
        <>
        <div className={styles.links}>
            <div className={styles.notes__info}>
                <Button className={styles.notes__button} onClick={openNotes}>
                    Display Notes &nbsp;&nbsp;
                    {artBlockNotes ? (
                        <RemoveIcon 
                            className={styles.notes__icon__style}
                        />
                    ):(
                        <>
                            <Tooltip title="Click to append">
                                <AddIcon 
                                    className={styles.notes__icon__style}
                                />
                            </Tooltip>
                        </>
                    )}
                </Button>
                {artBlockNotes ? (
                    <div className={styles.notes__info__text}>
                        WebGL is required. The rendering could be memory-intensive for some mobile devices, potentially leading to a browser crash on memory-limited devices. A modern browser on a desktop is recommended for optimal performance.
                    </div>
                ):(
                    null
                )}
            </div>
        </div>
    </>
    )
}

// Infos for AB specific tokens - Display Notes
export const ArtBlockDisplayAdditionalDetails = ({
    license,
    library
}:{
    license: string,
    library: string
}) => {
    const [artBlockNotes, setArtBlockNotes] = useState<boolean>(false)

    const openNotes = () => {
        setArtBlockNotes(!artBlockNotes);
    }
    return (
        <>
        <div className={styles.links}>
            <div className={styles.notes__info}>
                <Button className={styles.notes__button} onClick={openNotes}>
                    Additional Infos &nbsp;&nbsp;
                    {artBlockNotes ? (
                        <RemoveIcon 
                            className={styles.notes__icon__style}
                        />
                    ):(
                        <>
                            <Tooltip title="Click to append">
                                <AddIcon 
                                    className={styles.notes__icon__style}
                                />
                            </Tooltip>
                        </>
                    )}
                </Button>
                {artBlockNotes ? (
                    <>
                    <div className={styles.additional__info__text}>
                        <div className={styles.additional__info__headline}>
                            Sales Notes:
                        </div>
                        <div>
                            24-hour timed edition at the fixed price of 0.06 ETH
                        </div>
                    </div>
                    <div className={styles.links}>
                    {library ? (
                        <>
                        <div className={styles.link}>
                            <Label center className={styles.link__label}>Library</Label>
                            <h3>{library}</h3> 
                        </div>
                        </>
                    ):(
                        null
                    )}
                    {license ? (
                        <>
                        <div className={styles.link}>
                            <Label center className={styles.link__label}>License</Label>
                            <h3>{license}</h3> 
                        </div>
                        </>
                    ):(
                        null
                    )}
                    </div>
                    </>
                ):(
                    null
                )}
            </div>
        </div>
    </>
    )
}

export const IndividualTokenInfo = ({
    projectId,
    tokenId,
    supply
}: {
    projectId: number,
    tokenId: string,
    supply: number
}) => {

    const [owner, setOwner] = useState<string>("")
    const [mintedDate, setMintedDate] = useState<string>("")

    const getIndividualTokenInfo = async(id: string) => {
        const mintData:any = await getAbTokenMintInfo(id)

        if (mintData) {
            setOwner(mintData.owner.id)
            setMintedDate(mintData.createdAt)
        }
    }

    useEffect(() => {
        const id = projectId.toString()+tokenId.toString().padStart(6, "0")
        getIndividualTokenInfo(`${ARTBLOCKS_FLEX_CONTRACT_ADDRESS.toLowerCase()}-${id}`)
    }, [])
    return (
        <div className={styles.editions__info}>
            <div className={styles.editions__info__box}>
                <Label>Token</Label>
                <h2 style={{ paddingLeft: "0.5rem" }}>{tokenId} of {supply}</h2>
            </div>
            <div className={styles.editions__info__box}>
                <Label>Minted on</Label>
                <h2 style={{ paddingLeft: "0.5rem" }}>{getDateTime(Number(mintedDate)*1000)}</h2>
            </div>
            <div className={styles.editions__info__box}>
                <Label>Owned by</Label>
                <a href={`https://etherscan.io/address/${owner}`}><h2 style={{ paddingLeft: "0.5rem", marginBottom:"1rem"}}>{shorten(owner, 10)}</h2></a>
            </div>
        </div>
    )
}