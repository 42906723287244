import { CreatorDashboard } from "../../pages/creator/dashboard"
import { Mint } from "../../pages/creator/mint"
import { Auction } from "../../pages/creator/auction/index"
import { Swap } from "../../pages/creator/swap"
import type { IRoutes } from "../../types/routes"
import { Navigate } from "../../utils/redirect/redirect"
import { TezosAuction } from "../../pages/creator/auction/tezos-auction"
import { EthAuction } from "../../pages/creator/auction/eth-auction"
import { ProjectShell } from "../../pages/creator/project-shell"

export const routes: IRoutes = {
    routerId: 'creatorRouter',
    depth: 2,
    pages: [
        {
            path: '',
            element: () => <Navigate to="dashboard" replace/>,
            animate: false,
        },
        {
            path: '/dashboard',
            element: CreatorDashboard,
            animate: false,
        },
        {
            path: '/mint',
            element: Mint,
            animate: false,
        },
        {
            path: '/auction',
            element: Auction,
            animate: false,
        },
        {
            path: '/auction/tezos',
            element: TezosAuction,
            animate: false,
        },
        {
            path: '/auction/ethereum',
            element: EthAuction,
            animate: false,
        },
        {
            path: '/swap/*',
            element: Swap,
            animate: false,
        },
        {
            path: '/project-shells',
            element: ProjectShell,
            animate: false,
        }
    ],
}